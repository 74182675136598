@import "../foundation/global-settings.scss";

.close.notification-close {
  text-align: right;
  font-size: 1.5rem;
  padding: 0 0;
  top: 1rem;
  right: 0.75rem;
  opacity: 0.8;
  transition: $transition-2;

  &:hover {
    opacity: 1 !important;
  }
}

.icon-button {
  color: red;
  cursor: pointer;
}

.alert-heading {
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

//-----------------------------------------------------------------
//-----------------------------------------------------------------
// Normal Alerts

.alert.basic {
  color: $black;
  background-color: white;
  max-width: 420px;
  border-radius: $border-radius-medium;
  position: relative;
  padding: 0.75rem 1.25rem;
  padding-left: 0.75rem;
  border: 1px solid transparent;

  .icon {
    margin-right: 0.5rem;
    color: $cyan;
  }

  p {
    color: $black;
    margin: 0.25rem 0 0.5rem 2rem;
  }
}

.alert.success {
  color: #00aa6f;
  background-color: white;
  max-width: 420px;
  border-radius: $border-radius-medium;
  position: relative;
  padding: 0.75rem 1.25rem;
  padding-left: 0.75rem;
  border: 1px solid transparent;

  .icon {
    margin-right: 0.5rem;
    color: #00aa6f;
  }

  p {
    color: $black;
    margin: 0.25rem 0 0.5rem 2rem;
  }
}

.alert.error {
  color: #c43b38;
  background-color: white;
  max-width: 420px;
  border-radius: $border-radius-medium;
  position: relative;
  padding: 0.75rem 1.25rem;
  padding-left: 0.75rem;
  border: 1px solid transparent;

  .icon {
    margin-right: 0.5rem;
    color: #c43b38;
  }

  p {
    color: $black;
    margin: 0.25rem 0 0.5rem 2rem;
  }
}

//-----------------------------------------------------------------
//-----------------------------------------------------------------
// Color Alerts

.alert.basic-colour {
  color: $white;
  background-color: $cyan-80;
  max-width: 420px;
  border-radius: $border-radius-medium;
  position: relative;
  padding: 0.75rem 1.25rem;
  padding-left: 0.75rem;
  border: 1px solid transparent;

  .icon {
    margin-right: 0.5rem;
    color: $white;
  }

  p {
    color: $white;
    margin: 0.25rem 0 0.5rem 2rem;
  }
}

.alert.success-colour {
  color: $white;
  background-color: #00aa6f;
  max-width: 420px;
  border-radius: $border-radius-medium;
  position: relative;
  padding: 0.75rem 1.25rem;
  padding-left: 0.75rem;
  border: 1px solid transparent;

  .icon {
    margin-right: 0.5rem;
    color: $white;
  }

  p {
    color: $white;
    margin: 0.25rem 0 0.5rem 2rem;
  }
}

.alert.error-colour {
  color: $white;
  background-color: #c43b38;
  max-width: 420px;
  border-radius: $border-radius-medium;
  position: relative;
  padding: 0.75rem 1.25rem;
  padding-left: 0.75rem;
  border: 1px solid transparent;

  .icon {
    margin-right: 0.5rem;
    color: $white;
  }

  p {
    color: $white;
    margin: 0.25rem 0 0.5rem 2rem;
  }
}

//----------------------------------------------------------------------------------
//----------------------------------------------------------------------------------
// Badge Styles

.basic-badge {
  color: $black;
  background-color: $slate-10;
  display: inline-block;
  padding: 8px;
  font-size: $font-body-xsmall;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-small;
}

.colour-badge {
  color: $cyan;
  background-color: $cyan-10;
  display: inline-block;
  padding: 8px;
  font-size: $font-body-xsmall;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-small;
}

.dark-badge {
  color: white;
  background-color: $black-80;
  display: inline-block;
  padding: 8px;
  font-size: $font-body-xsmall;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-small;
}

//----------------------------------------------------------------------------------
//----------------------------------------------------------------------------------
// Badge Numbers Styles

.basic-number-badge {
  color: $black;
  background-color: $slate-10;
  padding: $spacing-padding-small;
  font-size: 14px;
  width: 32px;
  height: 32px;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-small;
}

.colour-number-badge {
  color: $cyan;
  background-color: $cyan-10;
  padding: $spacing-padding-small;
  width: 32px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-small;
}

.dark-number-badge {
  color: white;
  background-color: $black-80;
  padding: $spacing-padding-small;
  width: 32px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-small;
}

//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
