.carousel-inner {
  &.landing-p-carousel {
    border-radius: 0 !important;
  }
}

.carousel-caption {
  &.landing-p-carousel-caption {
    top: 136px !important;
    overflow-y: auto;
    @media screen and (max-width: 767px) {
      left: 2rem;
      top: 50px !important;
    }
    @media screen and (min-width: 992px) {
      width: 90%;
    }
  }
}

.carousel-caption {
  &.landing-p-carousel-caption {
    top: 136px !important;
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: 767px) {
      left: 2rem;
    }
  }
}

.landing-p-carousel-title {
  font-family: "Lato-black";
  font-size: clamp(44px, 4.4vw, 6.875rem);
  font-weight: 900;
  letter-spacing: -0.0275em;
  text-transform: none;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.banner-carousel .banner-carousel-item:not(.market-manual-carousel-item) {
  height: 545px;
  @media screen and (max-width: 767px) {
    height: 845px;
  }
}

.banner-carousel .market-manual-carousel-item {
  aspect-ratio: 16 / 9;
}

.carousel-control-prev {
  &.banner-carousel-ctr-prev {
    width: 60px !important;
  }
}

.carousel-control-next {
  &.banner-carousel-ctr-next {
    width: 100px !important;
  }
}
.img-overlay {
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}
