@import "./global-settings.scss";

//card border radius
//------------------------------------------------------------------------

.card-round {
  border-radius: $border-radius-medium;
}

.card-rounder {
  border-radius: $border-radius-large;
}


// Thickness border
//------------------------------------------------------------------------

.border-xs{
  border: $border-xs solid $slate-10;
}

.border-sm{
  border: $border-sm solid $slate-10;
}

//card elevations
//------------------------------------------------------------------------

.slight-raise {
  box-shadow: $shadow-slight-raise;
}

.noticeable-raise {
  box-shadow: $shadow-noticeable-raise;
}

.significant-raise {
  box-shadow: $shadow-significant-raise;
}

.extreme-raise {
  box-shadow: $shadow-extreme-raise;
}

// colour Shadow elevation
//------------------------------------------------------------------------

.shadow-cyan {
  box-shadow: $shadow-colour-cyan;
  transition: $transition-3;
  &:hover {
    box-shadow: $shadow-colour-cyan-hover;
  }
  &:active {
    box-shadow: none;
  }
}

.neu{
  background: linear-gradient(145deg, #00bbff, #009ed7);
//background: #00afef;
box-shadow:  0px 16px 40px #0093c9, 
             0px -16px 40px #00cbff;
            }