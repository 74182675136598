@import "../foundation/global-settings.scss";
// 1. heading borders

.heading-border-left {
    border-left: 0.5rem solid $cyan;
    margin-bottom: 1.25rem;
    padding-left: 1rem;
    @media screen and (max-width: 767px) {
        border-left: 0.25rem solid $cyan;
        padding-left: 0.75rem;
    }
}

.heading-border-right {
    border-right: 0.5rem solid $cyan;
    margin-bottom: 1.25rem;
    padding-right: 1rem;
    @media screen and (max-width: 767px) {
        border-right: 0.25rem solid $cyan;
        padding-right: 0.75rem;
    }
}

// 2. transforms
//---------------------------------------------------
.transformY-75 {
    transform: translateY(-75%);
    &--branchMap {
        @media screen and (min-width: 767px) {
            transform: translateY(-50%);
        }
    }
}

.transformY-50 {
    transform: translateY(-50%);
    &--branchMap {
        @media screen and (min-width: 767px) {
            transform: translateY(-50%);
        }
    }
    &--inMedia {
        @media screen and (min-width: 960px) {
            transform: translateY(-50%);
        }
    }
    &--houseView {
        transform: translateY(0%);
        @media screen and (min-width: 1232px) {
            transform: translateY(-50%);
        }
    }
}

.transformY-40 {
    transform: translateY(-40%);
}

.transformY-25 {
    transform: translateY(-25%);
}

.transformY-15 {
    transform: translateY(-15%);
}

.shadow-text {
    text-shadow: 0px 1px 0.5rem rgba(0, 0, 0, 0.1);
}

.dynamic-text-align {
    text-align: right;
    @media (max-width: 768px) {
        text-align: left;
    }
  }