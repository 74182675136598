@import "../foundation/global-settings.scss";

// 1. Card
//-----------------------------------------------------

// 1.1 card Appearance & Interaction

.card.card--psg {
  border: 1px solid $slate-10;
  border-radius: 1rem;
  transition: $transition-2;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
  }

  &:active {
    box-shadow: $shadow-slight-raise;
  }
}

// 1.2 card img

.card--img {
  padding: 1.25rem;
  padding-bottom: 0;
}

// 1.3 card body

.card-body {
  padding: 1.25rem 1rem;
}

// 1.4 focus ring

.card:focus {
  outline: none;
  outline-color: none;
  outline-style: none;
  outline-width: 0;
}

// ToDo: move to correct style sheet
// ----------------------------------

.container.width-960 {
  max-width: 960px !important;
  margin: 0 auto;
}

// 2. colour cards
//-------------------------------------

// 2.1 single colour

.card--colour_single {
  border: 1px solid $slate-10;
  border-radius: 1rem;

  &:hover {
    cursor: auto;
    box-shadow: none;
  }
}

.card-header.card--colour-header {
  border-radius: 1rem 1rem 0 0;
  padding: 0.75rem 0.75rem 0rem 0.75rem;
  height: 120px;
  border-bottom: none;

  &.inner {
    border-radius: 0;
    height: 90px;
  }

  &.outer {
    border-radius: 1rem 1rem 0 0;
    height: 90px;
  }
}

.card-body.card--colour-body {
  padding: 0.5rem 0.85rem;
}

.card.card--colour_single {
  margin-right: 4px;
}

.card--maxWidth {
  max-width: 165px;
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}

// color ratio

.ratio-card {
  min-height: 200px;
  border: 1px solid $black;
  border-radius: 16px;
  @media screen and (max-width: 767px) {
    p {
      font-size: 50%;
    }
  }
}

.cap-border-start {
  border-radius: 1rem 0 0 1rem;
}

.cap-border-end {
  border-radius: 0 1rem 1rem 0;
}

.card-size50 {
  min-height: 200px;

  @media screen and (max-width: 767px) {
    min-height: 250px;
  }
}

.card-size35 {
  min-height: 200px;

  @media screen and (max-width: 767px) {
    min-height: 175px;
  }
}

.card-size5 {
  min-height: 200px;

  @media screen and (max-width: 767px) {
    min-height: 25px;
  }
}

.cards-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
