@import "./global-settings.scss";

//Primary colours
//---------------
// Cyan
//-----------------------------------------------
.cyan {
  color: $cyan;
}

.cyan-bg {
  background-color: $cyan;
}

.cyan-80 {
  color: $cyan-80;
}

.cyan-bg-50 {
  background-color: $cyan-80;
}

.cyan-50 {
  color: $cyan-50;
}

.cyan-bg-50 {
  background-color: $cyan-50;
}

.cyan-30 {
  color: $cyan-30;
}

.cyan-bg-30 {
  background-color: $cyan-30;
}

.cyan-10 {
  color: $cyan-10;
}

.cyan-bg-10 {
  background-color: $cyan-10;
}

// Dark Black
//-----------------------------------------------

.dark-black {
  color: $dark-black;
}

// Black
//-----------------------------------------------

.black {
  color: $black;
}

.black-bg {
  background-color: $black;
}

.black-80 {
  color: $black-80;
}

.black-bg-80 {
  background-color: $black-80;
}

.black-50 {
  color: $black-50;
}

.black-bg-50 {
  background-color: $black-50;
}

.black-30 {
  color: $black-30;
}

.black-bg-30 {
  background-color: $black-30;
}

.black-10 {
  color: $black-10;
}

.black-bg-10 {
  background-color: $black-10;
}

// Slate + background
//-----------------------------------------------
.slate {
  color: $slate;
}

.slate-bg {
  background-color: $slate;
}

.slate-50 {
  color: $slate-50;
}

.slate-bg-50 {
  background-color: $slate-50;
}

.slate-30 {
  color: $slate-30;
}

.slate-bg-30 {
  background-color: $slate-30;
}

.slate-10 {
  color: $slate-10;
}

.slate-bg-10 {
  background-color: $slate-10;
}

.slate-bg-5 {
  background-color: $slate-5;
}

// Gold
//-----------------------------------------------

.gold {
  color: $gold;
}

.gold-bg {
  background-color: $gold;
}

.gold-50 {
  color: $gold-50;
}

.gold-bg-50 {
  background-color: $gold-50;
}

.gold-30 {
  color: $gold-30;
}

.gold-bg-30 {
  background-color: $gold-30;
}

.gold-10 {
  color: $gold-10;
}

.gold-bg-10 {
  background-color: $gold-10;
}

// white
//-----------------------------------------------

.white {
  color: $white;
}

.white-p p {
  color: $white;
}

.white-bg {
  background-color: $white;
}

.white-80 {
  color: $white-80;
}

.white-bg-80 {
  background-color: $white-80;
}

.white-50 {
  color: $white-50;
}

.white-bg-50 {
  background-color: $white-50;
}

.white-30 {
  color: $white-30;
}

.white-bg-30 {
  background-color: $white-30;
}

.white-10 {
  color: $white-10;
}

.white-bg-10 {
  background-color: $white-10;
}

//Secondary colours
//---------------

// Blue
//-----------------------------------------------

.blue-20 {
  color: $blue-20;
}

.blue-bg-20 {
  background-color: $blue-20;
}

.blue {
  color: $blue;
}

.blue-bg {
  background-color: $blue;
}

.blue-50 {
  color: $blue-50;
}

.blue-bg-50 {
  background-color: $blue-50;
}

.blue-30 {
  color: $blue-30;
}

.blue-bg-30 {
  background-color: $blue-30;
}

.blue-10 {
  color: $blue-10;
}

.blue-bg-10 {
  background-color: $blue-10;
}
.blue-bg-20 {
  background-color: $blue-20;
}

.blue-20 {
  color: $blue-20;
}

// Green
//-----------------------------------------------
.green-20 {
  color: $green;
}

.green-bg {
  background-color: $green;
}

.green {
  color: $green-20;
}

.green-bg-20 {
  background-color: $green-20;
}

.green-50 {
  color: $green-50;
}

.green-bg-50 {
  background-color: $green-50;
}

.green-30 {
  color: $green-30;
}

.green-bg-30 {
  background-color: $green-30;
}

.green-10 {
  color: $green-10;
}

.green-bg-10 {
  background-color: $green-10;
}

.green-bg-20 {
  background-color: $green-20;
}

.green-bg-20 {
  background-color: $green-20;
}

// Yellow
//-----------------------------------------------

.yellow {
  color: $yellow;
}

.yellow-bg {
  background-color: $yellow;
}

.yellow-50 {
  color: $yellow-50;
}

.yellow-bg-50 {
  background-color: $yellow-50;
}

.yellow-30 {
  color: $yellow-30;
}

.yellow-bg-30 {
  background-color: $yellow-30;
}

.yellow-10 {
  color: $yellow-10;
}

.yellow-bg-10 {
  background-color: $yellow-10;
}

// Purple
//-----------------------------------------------

.purple {
  color: $purple;
}

.purple-bg {
  background-color: $purple;
}

.purple-50 {
  color: $purple-50;
}

.purple-bg-50 {
  background-color: $purple-50;
}

.purple-30 {
  color: $purple-30;
}

.purple-bg-30 {
  background-color: $purple-30;
}

.purple-10 {
  color: $purple-10;
}

.purple-bg-10 {
  background-color: $purple-10;
}

// Red
//-----------------------------------------------

.red {
  color: $red;
}

.red-bg {
  background-color: $red;
}

.red-50 {
  color: $red-50;
}

.red-bg-50 {
  background-color: $red-50;
}

.red-30 {
  color: $red-30;
}

.red-bg-30 {
  background-color: $red-30;
}

.red-10 {
  color: $red-10;
}

.red-bg-10 {
  background-color: $red-10;
}

// gradient
//-----------------------------------------------

.gradient-cyan {
  color: $cyan-gradient;
}

.gradient-bg-cyan {
  background: $cyan-gradient;
}
