@import "../foundation/global-settings.scss";
//----------

.map-iframe {
  width: 600px;
  height: 400px;

  @media screen and (max-width: 767px) {
    width: 211px;
    height: 216px;
  }

  @media screen and (max-width: 1025px) {
    width: 294px;
    height: 337px;
  }
}

.placecard-container {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  max-width: 330px;
  width: 100%;
  background: white none repeat scroll 0% 0% / auto padding-box border-box;
  border-radius: 2px 2px 2px 2px;
  font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
  margin: 10px;
  padding: 9px 11px 9px 11px;
  overflow: hidden;
  z-index: 1000000;
  position: absolute;
}

.direction-icon {
  background: rgba(0, 0, 0, 0)
    url("https://maps.gstatic.com/mapfiles/embed/images/entity11.png") repeat
    scroll 0px 0px/70px 210px padding-box border-box;
  height: 22px;
  width: 22px;
  margin-right: auto;
  margin-left: auto;
}

.direction-link {
  display: block;
}

.iframe-container {
  min-height: 100vh;
}
