@import "../foundation/global-settings.scss";

.carousel-inner {
  border-radius: $border-radius-medium;
}

//  Captions styles
// -------------------------------------------------

.carousel-caption {
  position: absolute;
  bottom: $spacing-padding-medium;
  left: $spacing-padding-medium;
  z-index: 10;
  padding-top: $spacing-padding-medium;
  padding-bottom: 0;
  color: $white;
  text-align: left;
  //animation text focus in
  -webkit-animation: text-focus-in 0.75s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.75s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.carousel-heading {
  color: $white;
  font-size: $font-heading-medium;
  font-family: $lato-bold;

  @media screen and (max-width: 599px) {
    font-size: $font-heading-small;
  }
}

.carousel-content {
  color: $white;
  max-width: $paragraph-small;

  @media screen and (max-width: 599px) {
    font-size: $font-body-small;
  }
}

// controls
// -------------------------------------------------

.control-buttons {
  margin-top: $spacing-margin-medium;
}

// multi card slides
// -------------------------------------------------
.multi > img {
  border-radius: $border-radius-medium;
}

.multi > .carousel-caption {
  left: 2rem;
}

.col-md-3.multi {
  padding-right: 0px;
}

// carousel caption animation => text-focus-in
// -------------------------------------------------

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.btn-indicators > button-text {
  &.active > button { 
    background-color: #fff !important;
    color: #000 !important;
  }
}

.btn-indicators > button-text:hover > button {
    background-color: #fff !important;
    color: #000 !important;
}

#market-manual-indicators {
  height: 75%;
}

#market-manual-indicators > .carousel-inner{
  height: 95%;
}

a.market-indicator-prev > span.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

a.market-indicator-next > span.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}
