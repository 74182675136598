@import "./global-settings.scss";

// 1 Icons
//-------------------------------------------
.angle-down-alt {
  background: url("/assets/images/icons/angle-down-alt.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.angle-down {
  background: url("/assets/images/icons/angle-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.angle-left {
  background: url("/assets/images/icons/angle-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.angle-right {
  background: url("/assets/images/icons/angle-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.angle-up-alt {
  background: url("/assets/images/icons/angle-up-alt.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.angle-up {
  background: url("/assets/images/icons/angle-up.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.back-space {
  background: url("/assets/images/icons/back-space.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.bar-graph {
  background: url("/assets/images/icons/bar-graph.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.calendar {
  background: url("/assets/images/icons/calendar.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.check-circle {
  background: url("/assets/images/icons/check-circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.check {
  background: url("/assets/images/icons/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.clipboard {
  background: url("/assets/images/icons/clipboard.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.clock {
  background: url("/assets/images/icons/clock.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.delete {
  background: url("/assets/images/icons/delete.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.direction {
  background: url("/assets/images/icons/direction.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.ellipsis {
  background: url("/assets/images/icons/ellipsis.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.envelope {
  background: url("/assets/images/icons/envelope.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.exclamation-triangle {
  background: url("/assets/images/icons/exclamation-triangle.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.file-upload {
  background: url("/assets/images/icons/file-upload.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.file {
  background: url("/assets/images/icons/file.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.info-circle {
  background: url("/assets/images/icons/info-circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.location {
  background: url("/assets/images/icons/location.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.message {
  background: url("/assets/images/icons/message.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.notify {
  background: url("/assets/images/icons/notify.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.pad-lock {
  background: url("/assets/images/icons/pad-lock.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.pad-lock-open {
  background: url("/assets/images/icons/pad-lock-open.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.paper-plane {
  background: url("/assets/images/icons/paper-plane.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.pen {
  background: url("/assets/images/icons/pen.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.search {
  background: url("/assets/images/icons/search.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.trash {
  background: url("/assets/images/icons/trash.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

// 2. size
//----------------------------------------------

.xxxlarge-icon {
  height: 180px;
  width: 180px;
}

.xlarge-icon {
  height: 120px;
  width: 120px;
}

.large-icon {
  height: 40px;
  width: 40px;
}

.medium-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.small-icon {
  height: 16px;
  width: 16px;
}

.hero-img {
  height: calc(100vh - 116px);
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.2)
    ),
    url("/assets/images/home-page/all-funds-hero1.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  @media (max-width: 767px) {
    background-size: auto;
    background-position: 75% 10%;
  }
}
