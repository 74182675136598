//
@import "../foundation/global-settings.scss";
//
// Styles in this file are used, and are specific to all news related pages
//

//----------------------------------------------------------------------------------------
// 1. Latest news card row

//----------------------------------------------------------------------------------------

.news--card {
    border-radius: $border-radius-medium;
    box-shadow: $shadow-noticeable-raise;
    transition: $transition-2;
    border: none;

    .card-img-top {
        border-radius: $border-radius-medium $border-radius-medium 0 0;
        object-fit: cover;
        height: 10rem;
        @media screen and (max-width: 767px) {
            max-height: 10rem;
        }
    }
    .card-vid-top {
        border-radius: $border-radius-medium $border-radius-medium 0 0;
        object-fit: cover;
        height: 10rem;
        @media screen and (max-width: 767px) {
            max-height: 10rem;
        }
    }
    .card-body {
        padding: 0.75rem 1rem 2rem 1rem;
        h5.small-heading {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.1;
        }
        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &:hover {
        box-shadow: $shadow-significant-raise;
        transition: $transition-2;
    }
}


.news--card-calculators {
    border-radius: $border-radius-medium;
    box-shadow: $shadow-noticeable-raise;
    transition: $transition-2;
    border: none;

    .card-img-top {
        border-radius: $border-radius-medium $border-radius-medium 0 0;
        object-fit: cover;
        height: 10rem;
        @media screen and (max-width: 767px) {
            max-height: 10rem;
        }
    }
    .card-body {
        padding: 0.75rem 1rem 2rem 1rem;
        h5.small-heading {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.1;
        }
        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    &:hover {
        box-shadow: $shadow-significant-raise;
        transition: $transition-2;
    }
}


.card-deck .card.news--card {
    @media screen and (max-width: 992px) {
        flex: 1 1 40% !important;
        margin-right: 15px;
        margin-bottom: 30px;
        margin-left: 15px;
    }
}

.card-deck .card.news--card-calculators{
    @media screen and (max-width: 992px) {
        flex: 1 1 40% !important;
        margin-right: 15px;
        margin-bottom: 30px;
        margin-left: 15px;
    }
}
// 1.1 category tags inside card
//------------------------------

.card-tags > .basic-badge {
    font-size: 0.5625rem;
}

.card-tags > .basic-badge:not(:last-child) {
    margin-right: 0.5rem;
}

// header row news cards
//-----------------------------

.news--card.header-row {
    box-shadow: $shadow-extreme-raise;

    &:hover {
        box-shadow: $shadow-extreme-raise;
    }
}

.news--card-calculators.header-row {
    box-shadow: $shadow-extreme-raise;

    &:hover {
        box-shadow: $shadow-extreme-raise;
    }
}
//----------------------------------------------------------------------------------------
// 2. DIU card
//---------------------------------------------------------------------------------------

.diu--card {
    background-image: linear-gradient(
            180deg,
            rgba(30, 30, 30, 0) 0%,
            rgba(30, 30, 30, 0.1) 60%,
            rgba(30, 30, 30, 1) 100%
        ),
        url("../../images/home-page/diu-bg.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-extreme-raise;
    transition: $transition-2;
    padding: 3rem;

    &:hover {
        // box-shadow: $shadow-significant-raise;
        transition: $transition-2;
    }

    @media screen and (max-width: 767px) {
        min-height: 65vh;
        padding: 0rem 1rem 2rem 1rem;
    }
}

//----------------------------------------------------------------------------------------
// 3. cards below hero section - news landing page
//----------------------------------------------------------------------------------------

.daliy-investment-card {
    margin-top: -5rem;
    @media screen and (max-width: 767px) {
        margin-top: -3rem;
    }
}

//----------------------------------------------------------------------------------------
// 4. featured images article / DIU
//----------------------------------------------------------------------------------------

.featured-img--article {
    width: 100%; /* width of container */
    height: 100%; /* height of container */
    object-fit: cover;
    @media screen and (max-width: 767px) {
        height: 200px; /* height of container */
        margin-bottom: 1.25rem;
    }
}

.svg-bg-r.featured-img-bg {
    background-position: 10vw 0;
    background-size: cover;

    @media screen and (max-width: 1025px) {
        background-position: 0vw 0;
    }
    @media screen and (max-width: 767px) {
        background-position: 0vw 0;
    }
}

//----------------------------------------------------------------------------------------
// 5. article search section
//----------------------------------------------------------------------------------------

.newsSearch--card {
    border-radius: $border-radius-medium;
    box-shadow: $shadow-slight-raise;
    transition: $transition-2;
    border: none;
    padding: 2.25rem 2rem;
    @media screen and (max-width: 767px) {
        padding: 2rem 1.5rem;
    }
}

.largeArticle-cards {
    padding: 1rem 0 0 0;
}

.largeArticle-cards:not(:last-child) {
    border-bottom: 1px solid $slate-10;
}

//----------------------------------------------------------------------------------------
// 5. Diu article - search section
//----------------------------------------------------------------------------------------
.diuSearch-results--card {
    padding-top: 1rem;
}

.diuSearch-results--card:not(:last-child) {
    border-bottom: 1px solid $slate-10;
}

.article-search-results {
    padding-top: 1rem;
}
.article-search-results:not(:last-child) {
    border-bottom: 1px solid $slate-10;
}

//----------------------------------------------------------------------------------------
// 6. branch office - presentations
//----------------------------------------------------------------------------------------

.presentation--card {
    border-radius: $border-radius-medium;
    box-shadow: $shadow-slight-raise;
    transition: $transition-2;
    border: none;

    .card-img-top {
        border-radius: $border-radius-medium $border-radius-medium 0 0;
        object-fit: cover;
        @media screen and (max-width: 767px) {
            max-height: 10rem;
        }
    }
    .card-body {
        padding: 0.75rem 1rem 2rem 1rem;
    }

    &:hover {
        box-shadow: $shadow-significant-raise;
        transition: $transition-2;
    }
}

//----------------------------------------------------------------------------------------
// 6. sens results
//----------------------------------------------------------------------------------------
.sens-results-container {
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 400px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;

    @media (hover: hover) {
        &:hover {
            overflow-y: auto;
        }
    }
    @media screen and (max-width: 1023px) {
        overflow-y: auto;
    }
}

.sens-results:not(:last-child) {
    border-bottom: 1px solid $slate-10;
}

//-----------------------------------------------------------------------------------------
// Share price ticker
//-----------------------------------------------------------------------------------------

$duration: 10s;

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: transparent;
    padding-left: 100%;

    .ticker {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        line-height: 4rem;
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: $duration;
        animation-duration: $duration;

        &__item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
        }
    }
}

//------------------------------------------------------
// excerpt line clamps

.b-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.t-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

// news search results scrollable section

.result-scroll-section {
    max-height: 720px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    padding-right: 1rem;
}

.result-scroll-section-diu {
    max-height: 580px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    padding-right: 1rem;
}
