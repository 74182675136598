@import "../foundation/global-settings.scss";

//--------------------------------------------------------------------------
//  PSG ZA hero header sections - Index
//--------------------------------------------------------------------------
//
//  1. Home page
//  2. Financial advice
//  3. Save & Invest
//  4. Insurance
//  5. About us
//  6. News & publications
//  7. Careers
//  8. Contact
//  9. Support
//

//--------------------------------------------------------------------------
//  1. home page
//--------------------------------------------------------------------------

// 1.1 home page
//--------------

.home-hero {
    height: auto;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
    @media screen and (max-width: 768px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
        height: auto;
    }
}

//--------------------------------------------------------------------------
//  2. Financial advice
//--------------------------------------------------------------------------

// 2. how to invest
.howto-invest {
    height: calc(65vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

// 3.  forms and facts sheets
.formsAndFacts-page-hero {
    height: calc(75vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//4. all funds
.all-funds {
    height: calc(75vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//5. all-multimanaged-funds
.all-multimanaged-funds {
    height: calc(75vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//5. aboutUs PSG main
.aboutUs-Psg {
    height: calc(80vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//6. reprtsAndNotices
.reprtsAndNotices {
    height: calc(50vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

// 7. Glossary

.glossary-page-hero {
    height: calc(50vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

// 8. news

.news-page-hero {
    height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

// 9. Legal
.legal-hero {
    height: calc(50vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.legal-hero-section {
    height: 420px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 599px) {
        height: 420px;
    }
    &--cards {
        margin-top: -7rem;
    }
}

// Scheme particulars
.scheme-hero {
    height: calc(50vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//6. contact us
.contactUs {
    height: calc(65vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 599px) {
        height: 360px;
    }
}

//7 fund fact sheet
.factSheet-hero {
    height: calc(50vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//8 saveAndInvest
.saveAndInvest {
    height: calc(75vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//savings-page
.savings-page {
    height: calc(560px - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 767px) {
        height: calc(480px - 126px);
    }
}

//retirement planning
.retirement-planning {
    height: calc(600px - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 767px) {
        height: calc(420px - 126px);
    }
}

//all-psg-funds
.all-psg-funds {
    height: calc(75vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

// comapre products
.compareHero {
    height: calc(60vh - 126px);
    min-height: 380px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center -16vw;
    background-size: cover;
}

//insurance page
.insurence-hero-section {
    height: calc(75vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

//business-investment-services page
//-------------------------------------------------------------------------

.business-investment-services {
    height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        height: calc(90vh - 126px);
    }
    @media screen and (max-width: 767px) {
        height: calc(125vh - 126px);
    }
}
// psg-multi-managed-funds page
//--------------------------------------------------------------------------

.psg-multi-managed-funds {
    height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 767px) {
        height: auto;
        min-height: 660px;
    }
}

// psg-single-managed-funds
//--------------------------------------------------------------------------

.single-managed-funds-hero {
    height: calc(600px - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 2rem !important;
}

// stock broking
.stockbroking-hero {
    height: auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    &--cards {
        margin-top: -5rem;
    }
}

.educate-yourself-hero {
    height: calc(560px - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 767px) {
        height: calc(480px - 126px);
    }
}

//--------------------------------------------------------------------------
//  2. Financial advice
//--------------------------------------------------------------------------

//  find-an-adviser
//-----------------

.find-an-adviser-hero {
    height: auto;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media screen and (max-width: 599px) {
    }
}

//  branch office
//---------------
.branch-office {
    height: 350px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media screen and (max-width: 767px) {
        height: calc(75vh - 126px);
        background-position: center center;
        background-size: cover;
    }

}

.branch-office-container {
    position: relative !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
 }

 .banner-m{
    position: relative;
    z-index: 1;
    height: 100%;
    max-width: 1914px;
    margin: 0 auto;
 }



//  adviser-profile page
//-----------------------
.adviser-profile {
    height: 23rem;
    @media screen and (max-width: 767px) {
        height: calc(480px - 126px);
    }
}

//  Advice for Yourself
//----------------------
.Advice-forYourself {
    height: 420px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media screen and (max-width: 599px) {
        height: 420px;
    }

    &--cards {
        margin-top: -7rem;
    }
}
.Advice-forYourEstate {
    height: 480px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media screen and (max-width: 599px) {
        height: 532px;
    }

    &--cards {
        margin-top: -7rem;
    }
}

//--------------------------------------------------------------------------
// About us
//--------------------------------------------------------------------------

// Insure about
//-------------

.about-insure-hero {
    height: calc(65vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        height: calc(600px - 126px);
    }
}

//  Investor relations
//--------------------
.investor-relations-hero {
    height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 768px) {
        height: auto;
        padding: 3rem 1rem;
    }
}

//  Investor information
//----------------------

.investor-info-hero {
    height: calc(65vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 768px) {
        height: auto;
        padding: 3rem 1rem;
    }
}

//  Corporate governance
//-----------------------
.corp-governance-hero {
    height: auto;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.corp-governance-hero-card {
    margin-top: -64px;
    @media screen and (max-width: 767px) {
        margin-top: -48px;
    }
}

//  SENS & Share data
//--------------------
.sens-hero {
    height: auto;
    background-image: linear-gradient(235deg, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 1) 100%),
        url("../../images/home-page/finance-graphs-and-trading.jpg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.sens-card-row {
    margin-top: -72px;
}

// psg-wealth page
//--------------------------------------------------------------------------

.psg-wealth {
    height: calc(90vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media screen and (max-width: 767px) {
        height: auto;
        padding: 3rem 1rem 4rem 1rem;
    }
}

// psg-asset-management page

.psg-asset-management {
    height: auto;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 5rem;
    @media screen and (max-width: 767px) {
        padding: 3rem 1rem 4rem 1rem;
    }
}

// financial-advice
.financial-advice-hero {
    height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        height: calc(90vh - 126px);
    }
}

// employee-page
.employee-page-hero {
    height: 100%;
}
// week-ahead
.week-ahead-hero {
    min-height: calc(65vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(90vh - 126px);
    }
}

// markets-manual
.markets-manual-hero {
    height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        height: calc(90vh - 126px);
    }
}

// macroeconomic-insights
.macroeconomic-insights-hero {
    min-height: calc(90vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}

// fund-research
.fund-research-hero {
    min-height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(90vh - 126px);
    }
}

// equity-research
.equity-research-hero {
    min-height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(90vh - 126px);
    }
}

//In the media
.in-the-media-hero {
    min-height: calc(65vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(600px - 126px);
    }
}

//House view equity portfolios
.house-view-equity-hero {
    min-height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(600px - 126px);
    }
}

//Fund of funds
.fund-of-funds-hero {
    min-height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(600px - 126px);
    }
}

.event-research-strategy-report-hero {
    min-height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(600px - 126px);
    }   
}

.event-impact-assessment-hero {
    min-height: calc(85vh - 126px);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 599px) {
        min-height: calc(600px - 126px);
    } 
}

// start-your-journey
.start-your-journey-hero {
    height: 320px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    @media screen and (max-width: 599px) {
    }
}

//--------------------------------------------------------------------------
//  7. Careers
//--------------------------------------------------------------------------

// 7.1 corporate culure
//---------------------
.corporate-culure {
    &--hero {
        height: 480px;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @media screen and (max-width: 599px) {
        }
    }

    &--card-row {
        margin-top: -7rem;
    }
}

// 7.2 Graduate programme
//-----------------------
.graduate-programme {
    &--hero {
        height: 520px;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @media screen and (max-width: 599px) {
        }
    }

    &--card-row {
        margin-top: -7rem;
    }
}

// 7.3 Partner with psg
//---------------------
.partner-with-psg {
    &--hero {
        height: 480px;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        @media screen and (max-width: 599px) {
        }
    }

    &--card-row {
        margin-top: -8rem;
    }
}

// 7.4 Become an adviser
//----------------------
.become-an-adviser {
    &--hero {
        height: 480px;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @media screen and (max-width: 599px) {
        }
    }

    &--card-row {
        margin-top: -7rem;
    }
}

//--------------------------------------------------------------------------
//  8. contact us
//--------------------------------------------------------------------------

// 8.1 contact
//-------------

//--------------------------------------------------------------------------
//  9. support pages
//--------------------------------------------------------------------------

// 9.1 forms and fact sheets
//---------------------------
.forms-and-factSheet {
    &--hero {
        height: 26rem;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 5rem;
        padding-bottom: 4rem;

        @media screen and (max-width: 599px) {
            height: 22rem;
        }
    }

    &--card-row {
        margin-top: -13rem;
    }
}

// 9.1 FAQ
//---------
.faq-hero-section {
    height: 420px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media screen and (max-width: 599px) {
        height: 420px;
    }

    &--cards {
        margin-top: -7rem;
    }
}
// 9.2 legal
//----------

// 9.3 glossary
//-------------

// 9.4 financial tools
//---------------------
.financial-tools {
    &--hero {
        height: 20rem;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        @media screen and (max-width: 599px) {
            height: 12rem;
        }
    }
}

//--------------------------------------------------------------------------
//
//
//
//FAG page

//diu economic calender
.diu-economic-calender-section {
    height: 420px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 599px) {
        height: 420px;
    }
    &--cards {
        margin-top: -7rem;
    }
}

//--------------------------------------------------------------------------
//animated down arrow in hero section
//--------------------------------------------------------------------------

.down--arrow {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: $white;
    display: none;
    -webkit-animation: slide-down-up 2s ease-in-out infinite alternate-reverse both;
    animation: slide-down-up 2s ease-in-out infinite alternate-reverse both;
    @media screen and (max-width: 599px) {
        display: block;
    }
}

@-webkit-keyframes slide-down-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}
@keyframes slide-down-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

.research-insights {
    &--hero {
        height: 480px;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        @media screen and (max-width: 599px) {
            height: 360px;
        }
    }

    &--card-row {
        margin-top: -10rem;
    }
}

.banner-card {
    position: relative;
    top: -15vh;
}