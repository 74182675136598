//--------------------------------------------------------------
// Index
//--------------------------------------------------------------

// 1. Imports
// 2. Mixins
// 3. Sizes

//--------------------------------------------------------------

// 1. Imports
//--------------------------------------------------------------
$icomoon-font-family: "psgIconsV1.2" !default;
$icomoon-font-path: "/assets/fonts/icon-fonts" !default;

// 2. Mixins
//--------------------------------------------------------------

$icon-menu-path1: "\e938";
$icon-menu-path2: "\e939";
$icon-minus: "\e93a";
$icon-plus: "\e93b";
$icon-exit: "\e93c";
$icon-angle-down: "\e900";
$icon-angle-up: "\e901";
$icon-angle-left: "\e902";
$icon-angle-right: "\e935";
$icon-angle-up-alt-path1: "\e903";
$icon-angle-up-alt-path2: "\e904";
$icon-angle-down-alt-path1: "\e936";
$icon-angle-down-alt-path2: "\e937";
$icon-back-space-path1: "\e905";
$icon-back-space-path2: "\e906";
$icon-bar-graph-path1: "\e907";
$icon-bar-graph-path2: "\e908";
$icon-bar-graph-path3: "\e909";
$icon-calendar-path1: "\e90a";
$icon-calendar-path2: "\e90b";
$icon-check: "\e90c";
$icon-check-circle-path1: "\e90d";
$icon-check-circle-path2: "\e90e";
$icon-clock-path1: "\e90f";
$icon-clock-path2: "\e910";
$icon-delete-path1: "\e911";
$icon-delete-path2: "\e912";
$icon-direction: "\e913";
$icon-ellipsis: "\e914";
$icon-envelope-path1: "\e915";
$icon-envelope-path2: "\e916";
$icon-exclamation-triangle-path1: "\e917";
$icon-exclamation-triangle-path2: "\e918";
$icon-exclamation-triangle-path3: "\e919";
$icon-file-path1: "\e91a";
$icon-file-path2: "\e91b";
$icon-file-upload-path1: "\e91c";
$icon-file-upload-path2: "\e91d";
$icon-file-upload-path3: "\e91e";
$icon-info-circle-path1: "\e91f";
$icon-info-circle-path2: "\e920";
$icon-info-circle-path3: "\e921";
$icon-location-path1: "\e922";
$icon-location-path2: "\e923";
$icon-message-path1: "\e924";
$icon-message-path2: "\e925";
$icon-notify-path1: "\e926";
$icon-notify-path2: "\e927";
$icon-padlock-path1: "\e928";
$icon-padlock-path2: "\e929";
$icon-padlock-open-path1: "\e92a";
$icon-padlock-open-path2: "\e92b";
$icon-paper-plane-path1: "\e92c";
$icon-paper-plane-path2: "\e92d";
$icon-pen-path1: "\e92e";
$icon-pen-path2: "\e92f";
$icon-search-path1: "\e930";
$icon-search-path2: "\e931";
$icon-search-path3: "\e932";
$icon-trash-path1: "\e933";
$icon-trash-path2: "\e934";
$icon-login-path1: "\e93d";
$icon-login-path2: "\e93e";
$icon-phone-path1: "\e93f";
$icon-phone-path2: "\e940";
$icon-twitter: "\e941";
$icon-linkedin: "\e942";
$icon-facebook: "\e943";
$icon-whatsapp: "\e95d";
$icon-youtube-path1: "\e944";
$icon-youtube-path2: "\e945";
$icon-reset-path1: "\e946";
$icon-reset-path2: "\e947";
$icon-share-path1: "\e948";
$icon-share-path2: "\e949";
$icon-window-path1: "\e94a";
$icon-window-path2: "\e94b";
$icon-window-path3: "\e94c";
$icon-adviser-path1: "\e94d";
$icon-adviser-path2: "\e94e";
$icon-article-path1: "\e94f";
$icon-article-path2: "\e950";
$icon-article-path3: "\e951";
$icon-copy-path1: "\e952";
$icon-copy-path2: "\e953";
$icon-copy-path3: "\e954";
$icon-file-download-path1: "\e955";
$icon-file-download-path2: "\e956";
$icon-file-download-path3: "\e957";
$icon-line-graph-path1: "\e958";
$icon-line-graph-path2: "\e959";
$icon-line-graph-path3: "\e95a";
$icon-question-path1: "\e95b";
$icon-question-path2: "\e95c";

//------------------------------------------------------------------------

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7orvns");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7orvns#iefix") format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7orvns") format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7orvns") format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7orvns##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu .path1 {
  &:before {
    content: $icon-menu-path1;
  }
}
.icon-menu .path2 {
  &:before {
    content: $icon-menu-path2;
    margin-left: -1em;
    opacity: 0.4;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-up-alt .path1 {
  &:before {
    content: $icon-angle-up-alt-path1;
    opacity: 0.2;
  }
}
.icon-angle-up-alt .path2 {
  &:before {
    content: $icon-angle-up-alt-path2;
    margin-left: -1em;
  }
}
.icon-angle-down-alt .path1 {
  &:before {
    content: $icon-angle-down-alt-path1;
    opacity: 0.2;
  }
}
.icon-angle-down-alt .path2 {
  &:before {
    content: $icon-angle-down-alt-path2;
    margin-left: -1em;
  }
}
.icon-back-space .path1 {
  &:before {
    content: $icon-back-space-path1;
    opacity: 0.3;
  }
}
.icon-back-space .path2 {
  &:before {
    content: $icon-back-space-path2;
    margin-left: -1em;
  }
}
.icon-bar-graph .path1 {
  &:before {
    content: $icon-bar-graph-path1;
    opacity: 0.2;
  }
}
.icon-bar-graph .path2 {
  &:before {
    content: $icon-bar-graph-path2;
    margin-left: -1em;
  }
}
.icon-bar-graph .path3 {
  &:before {
    content: $icon-bar-graph-path3;
    margin-left: -1em;
    opacity: 0.4;
  }
}
.icon-calendar .path1 {
  &:before {
    content: $icon-calendar-path1;
    opacity: 0.3;
  }
}
.icon-calendar .path2 {
  &:before {
    content: $icon-calendar-path2;
    margin-left: -1em;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-check-circle .path1 {
  &:before {
    content: $icon-check-circle-path1;
    opacity: 0.2;
  }
}
.icon-check-circle .path2 {
  &:before {
    content: $icon-check-circle-path2;
    margin-left: -1em;
  }
}
.icon-clock .path1 {
  &:before {
    content: $icon-clock-path1;
  }
}
.icon-clock .path2 {
  &:before {
    content: $icon-clock-path2;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-delete .path1 {
  &:before {
    content: $icon-delete-path1;
    opacity: 0.2;
  }
}
.icon-delete .path2 {
  &:before {
    content: $icon-delete-path2;
    margin-left: -1em;
  }
}
.icon-direction {
  &:before {
    content: $icon-direction;
  }
}
.icon-ellipsis {
  &:before {
    content: $icon-ellipsis;
  }
}
.icon-envelope .path1 {
  &:before {
    content: $icon-envelope-path1;
    opacity: 0.3;
  }
}
.icon-envelope .path2 {
  &:before {
    content: $icon-envelope-path2;
    margin-left: -1em;
  }
}
.icon-exclamation-triangle .path1 {
  &:before {
    content: $icon-exclamation-triangle-path1;
    opacity: 0.3;
  }
}
.icon-exclamation-triangle .path2 {
  &:before {
    content: $icon-exclamation-triangle-path2;
    margin-left: -1em;
  }
}
.icon-exclamation-triangle .path3 {
  &:before {
    content: $icon-exclamation-triangle-path3;
    margin-left: -1em;
  }
}
.icon-file .path1 {
  &:before {
    content: $icon-file-path1;
    opacity: 0.3;
  }
}
.icon-file .path2 {
  &:before {
    content: $icon-file-path2;
    margin-left: -1em;
  }
}
.icon-file-upload .path1 {
  &:before {
    content: $icon-file-upload-path1;
    opacity: 0.3;
  }
}
.icon-file-upload .path2 {
  &:before {
    content: $icon-file-upload-path2;
    margin-left: -1em;
  }
}
.icon-file-upload .path3 {
  &:before {
    content: $icon-file-upload-path3;
    margin-left: -1em;
  }
}
.icon-info-circle .path1 {
  &:before {
    content: $icon-info-circle-path1;
    opacity: 0.3;
  }
}
.icon-info-circle .path2 {
  &:before {
    content: $icon-info-circle-path2;
    margin-left: -1em;
  }
}
.icon-info-circle .path3 {
  &:before {
    content: $icon-info-circle-path3;
    margin-left: -1em;
  }
}
.icon-location .path1 {
  &:before {
    content: $icon-location-path1;
    opacity: 0.3;
  }
}
.icon-location .path2 {
  &:before {
    content: $icon-location-path2;
    margin-left: -1em;
  }
}
.icon-message .path1 {
  &:before {
    content: $icon-message-path1;
  }
}
.icon-message .path2 {
  &:before {
    content: $icon-message-path2;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-notify .path1 {
  &:before {
    content: $icon-notify-path1;
  }
}
.icon-notify .path2 {
  &:before {
    content: $icon-notify-path2;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-padlock .path1 {
  &:before {
    content: $icon-padlock-path1;
    opacity: 0.3;
  }
}
.icon-padlock .path2 {
  &:before {
    content: $icon-padlock-path2;
    margin-left: -1em;
  }
}
.icon-padlock-open .path1 {
  &:before {
    content: $icon-padlock-open-path1;
  }
}
.icon-padlock-open .path2 {
  &:before {
    content: $icon-padlock-open-path2;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-paper-plane .path1 {
  &:before {
    content: $icon-paper-plane-path1;
    opacity: 0.3;
  }
}
.icon-paper-plane .path2 {
  &:before {
    content: $icon-paper-plane-path2;
    margin-left: -1em;
  }
}
.icon-pen .path1 {
  &:before {
    content: $icon-pen-path1;
    opacity: 0.4;
  }
}
.icon-pen .path2 {
  &:before {
    content: $icon-pen-path2;
    margin-left: -1em;
  }
}
.icon-search .path1 {
  &:before {
    content: $icon-search-path1;
    opacity: 0.4;
  }
}
.icon-search .path2 {
  &:before {
    content: $icon-search-path2;
    margin-left: -1em;
    opacity: 0.05;
  }
}
.icon-search .path3 {
  &:before {
    content: $icon-search-path3;
    margin-left: -1em;
  }
}
.icon-trash .path1 {
  &:before {
    content: $icon-trash-path1;
    opacity: 0.3;
  }
}
.icon-trash .path2 {
  &:before {
    content: $icon-trash-path2;
    margin-left: -1em;
  }
}
.icon-login .path1 {
  &:before {
    content: $icon-login-path1;
    opacity: 0.3;
  }
}
.icon-login .path2 {
  &:before {
    content: $icon-login-path2;
    margin-left: -1em;
  }
}
.icon-phone .path1 {
  &:before {
    content: $icon-phone-path1;
    opacity: 0.3;
  }
}
.icon-phone .path2 {
  &:before {
    content: $icon-phone-path2;
    margin-left: -1em;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-youtube .path1 {
  &:before {
    content: $icon-youtube-path1;
    opacity: 0.3;
  }
}
.icon-youtube .path2 {
  &:before {
    content: $icon-youtube-path2;
    margin-left: -1em;
  }
}

.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}

.icon-reset .path1 {
  &:before {
    content: $icon-reset-path1;
    opacity: 0.4;
  }
}
.icon-reset .path2 {
  &:before {
    content: $icon-reset-path2;
    margin-left: -1em;
  }
}
.icon-share .path1 {
  &:before {
    content: $icon-share-path1;
    opacity: 0.6;
  }
}
.icon-share .path2 {
  &:before {
    content: $icon-share-path2;
    margin-left: -1em;
  }
}
.icon-window .path1 {
  &:before {
    content: $icon-window-path1;
    opacity: 0.3;
  }
}
.icon-window .path2 {
  &:before {
    content: $icon-window-path2;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-window .path3 {
  &:before {
    content: $icon-window-path3;
    margin-left: -1em;
  }
}
.icon-adviser .path1 {
  &:before {
    content: $icon-adviser-path1;
    opacity: 0.3;
  }
}
.icon-adviser .path2 {
  &:before {
    content: $icon-adviser-path2;
    margin-left: -1em;
  }
}
.icon-article .path1 {
  &:before {
    content: $icon-article-path1;
    opacity: 0.3;
  }
}
.icon-article .path2 {
  &:before {
    content: $icon-article-path2;
    margin-left: -1em;
  }
}
.icon-article .path3 {
  &:before {
    content: $icon-article-path3;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-copy .path1 {
  &:before {
    content: $icon-copy-path1;
    opacity: 0.5;
  }
}
.icon-copy .path2 {
  &:before {
    content: $icon-copy-path2;
    margin-left: -1em;
  }
}
.icon-copy .path3 {
  &:before {
    content: $icon-copy-path3;
    margin-left: -1em;
    opacity: 0.3;
  }
}
.icon-file-download .path1 {
  &:before {
    content: $icon-file-download-path1;
    opacity: 0.3;
  }
}
.icon-file-download .path2 {
  &:before {
    content: $icon-file-download-path2;
    margin-left: -1em;
  }
}
.icon-file-download .path3 {
  &:before {
    content: $icon-file-download-path3;
    margin-left: -1em;
  }
}
.icon-line-graph .path1 {
  &:before {
    content: $icon-line-graph-path1;
    opacity: 0.3;
  }
}
.icon-line-graph .path2 {
  &:before {
    content: $icon-line-graph-path2;
    margin-left: -1em;
    opacity: 0.4;
  }
}
.icon-line-graph .path3 {
  &:before {
    content: $icon-line-graph-path3;
    margin-left: -1em;
  }
}
.icon-question .path1 {
  &:before {
    content: $icon-question-path1;
    opacity: 0.3;
  }
}
.icon-question .path2 {
  &:before {
    content: $icon-question-path2;
    margin-left: -1em;
  }
}

// 3. sizes
//--------------------------------------------------------------

.icon-xs {
  font-size: 0.75rem;
}

.icon-sm {
  font-size: 1.25rem;
}

.icon-md {
  font-size: 1.5rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}
