//bottom border
.bottom-border{
 border-bottom: 5px solid $cyan;
    @media screen and (max-width: 767px) {
        border-bottom: none;
    }
}

.bottom-border-xs{
    border-bottom: 2px solid $cyan;
       @media screen and (max-width: 767px) {
           border-bottom: none;
       }
   }

//for yourself page
.carousel-bb-transform{
    transform: translate(-137px, 16px);
}

.red-border-class{
    border: 1px solid red !important;
}

.card-blue-border{
    border: 3px solid #00afef!important;
}