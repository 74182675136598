//------------------------------------------------------------
// add class to make butons 100% on mobile
// wrap button in a div a use class on that div
//------------------------------------------------------------

.btn-100-sm > button-text > button.button {
    @media screen and (max-width: 599px) {
        width: 100%;
    }
}

.btn-100-sm > button-icon > button.button {
    @media screen and (max-width: 599px) {
        width: 100%;
    }
}

.btn-100-funds > button-text > button.button {
    width: 55%;
    @media screen and (max-width: 599px) {
        width: 100%;
    }
    @media (max-width: 1024px) {
        width: 64%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
}

//------------------------------------------------------------
// add spacing between btns if theres more than one inline
//------------------------------------------------------------


.btn-row > button-text > button.button {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
}

.btn-row-nowrap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
}

.backToTop {
    position: fixed;
    bottom: 16px;
    right: 16px;
}

.btn-w > button-text > button.button {
    width: 180px;
}

.btn-mb > button-text > button.button {
    margin-bottom: 15px;
}

button-text > button.button.btn-sm {
    padding: 0.25rem 0.5rem !important;    
    font-size: 12px !important;
    line-height: 1 !important;
}