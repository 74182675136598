//---------------------------------------------------------------------

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// global -- product image

.prod-img {
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 256px;
  }
}

// contact us page
.contact-img {
  transform: translateY(-12.5%);
  z-index: 1;
  height: 320px;
  @media screen and (max-width: 767px) {
    transform: none;
    margin-top: 1rem;
    margin-bottom: 2rem;
    height: auto;
  }
}

// drop shadow filter for pngs
//
.drop-filter {
  filter: drop-shadow(0px 3px 2rem rgba(0, 0, 0, 0.2));
}

// about page
//
.about-us-fw {
  height: auto;
  @media screen and (min-width: 767px) {
    height: 30rem;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.img-size {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  box-shadow: 0px 2px 1rem -0.25rem rgb(0 0 0 / 15%);
  height: 18rem;
  cursor: pointer;
}

.office-img {
  width: 450px;
  height: 450px;
  @media (max-width: 768px) {
    height: 350px;
    width: 350px;
  }
}

.mobile-advisor-branch-img {
  display: none;
  @media (max-width: 768px) {
      display: block;
  }
}

.advisor-branch-img {
  display: block;
  @media (max-width: 768px) {
      display: none;
  }
}

.newsletter-img {
  padding: 10px;
  width: 250px;
  float: left;
  @media (max-width: 576px) {
    display: block;
    padding: 0px;
    width: 100%;
    float: none;
  }
}