@import "../foundation/global-settings.scss";
//-----------------

// campaign
.campaign-hero {
  height: auto;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media screen and (max-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 8rem;
    height: auto;
  }

  &--cards {
    margin-top: -5rem;
  }
}

.campaign-hero-GSK {
  height: auto;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 10rem;
  padding-bottom: 10rem;

  @media screen and (max-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 8rem;
    height: auto;
  }

  &--cards {
    margin-top: -8rem;
  }
}

.blockText {
  background-color: $white-80;
  color: $cyan;
  padding: 0rem 1rem 0rem 0.75rem;
  border-radius: 0.125rem;
  box-shadow: none;
  text-shadow: none;
}

.campaignHeading {
  line-height: 1.2;
}

.largeQuote {
  line-height: 1.2;
}

.card {
  &.campaign-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px;
    border-radius: 1rem;
    box-shadow: $shadow-extreme-raise;

    & > .card-img {
      border-radius: 1rem;
      -o-object-fit: cover;
      object-fit: cover;
      min-height: 274px;
    }

    & > .card-img-overlay {
      top: auto;
      bottom: 0;
      padding: 1.5rem;
      border-radius: 1rem;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      background-image: linear-gradient(
        235deg,
        rgba(30, 30, 30, 0) 0%,
        rgba(30, 30, 30, 1) 100%
      );
    }
  }
}

.blue-line {
  width: 50%;
  height: 0.5rem;
  background-color: $cyan;
  position: absolute;
  top: 25%;
  transform: translateX(-50%);
}

.bg-left {
  background: url("../../images/background-svg/campaign-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/campaign-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 1367px) {
    background: url("../../images/background-svg/campaign-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.bg--left {
  background: url("../../images/background-svg/campaign-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/campaign-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 1367px) {
    background: url("../../images/background-svg/campaign-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }
}
//falcon
.falcon-hero {
  height: auto;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media screen and (max-width: 768px) {
    height: auto;
    background-position: right;
  }

  &--cards {
    margin-top: -6rem;
  }
}

.zoomout-banner-container {
  position: relative !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.zoomout-banner-m {
  position: relative;
  padding: 0 !important;
  height: 100%;
  max-width: 1914px;
  margin: 0 auto;
}
