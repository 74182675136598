@import "./global-settings.scss";

// Float
//------------------------------------------------------------------------

.float-left {
    float: left;
}

.float-right {
    float: right;
}

// Width
//------------------------------------------------------------------------

.width-100 {
    width: 100%;
}

// Padding
//------------------------------------------------------------------------

.padding-tight {
    padding: $spacing-padding-tight;
}

// Spacers
//------------------------------------------------------------------------

.spacer-compact {
    height: $spacing-padding-compact;
}

// Display
//------------------------------------------------------------------------

.inline-block {
    display: inline-block;
}

.inline-flex {
    display: inline-flex;
}