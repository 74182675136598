@import "./global-settings.scss";

// Titles
//-------

.mega-title {
  font-family: $lato-black;
  font-size: $font-title-xxxlarge;
  font-weight: $font-weight-black;
  color: $black;
  letter-spacing: -0.0275em;
  text-transform: none;
  line-height: 1;
}

.mega-title--page-title {
  font-family: $lato-black;
  font-size: clamp(64px, 7vw, 6.875rem);
  font-weight: $font-weight-black;
  color: black;
  letter-spacing: -0.0275em;
  text-transform: none;
  line-height: 1;
  margin-bottom: 0.5rem;
  &.news-page {
    font-size: clamp(42px, 14vw, 6rem);
  }
  &.home-page {
    font-size: clamp(54px, 7vw, 6.875rem);
  }
  &.savings {
    font-size: clamp(54px, 7vw, 6.875rem);
  }
  &.sm-funds {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.mm-funds {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.corpG {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.about-wealth {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.assetM {
    font-size: clamp(42px, 7vw, 6.875rem);
  }
  &.insurance {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.financial-advice-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.falconHeading {
    font-size: clamp(37px, 6vw, 5rem);
  }
  &.campaignHeading {
    font-size: clamp(37px, 6vw, 5rem);
  }
  &.week-ahead-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.markets-manual-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.macroeconomic-insights-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.fund-research-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.equity-research-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.house-view-equity-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.fund-of-funds-header {
    font-size: clamp(42px, 7vw, 6rem);
  }
  &.start-your-journey-header {
    font-size: clamp(42px, 7vw, 5rem);
  }
  &.start-your-journey-content-heading {
    font-size: clamp(37px, 6vw, 4.9rem);
  }
  &.grey {
    color: $grey;
  }
}

.mega-title--mobile {
  font-family: $lato-black;
  font-size: clamp(42px, 7vw, 6rem);
  font-weight: $font-weight-black;
  color: black;
  letter-spacing: -0.0275em;
  text-transform: none;
  line-height: 1;
}

.section-title {
  font-family: $lato-black;
  font-size: $font-title-xxlarge;
  font-weight: $font-weight-black;
  color: $black;
  letter-spacing: -0.0275em;
  text-transform: none;
  line-height: 1;
}

.section-title--mobile {
  font-family: $lato-black;
  font-size: clamp(36px, 5.75vw, 4.5rem);
  font-weight: $font-weight-black;
  color: $black;
  letter-spacing: -0.0275em;
  text-transform: none;
  line-height: 1;
}

.section-title {
  &--product {
    font-family: $lato-black;
    font-size: clamp(36px, 5vw, 4rem);
    font-weight: $font-weight-black;
    color: $black;
    letter-spacing: -0.0275em;
    text-transform: none;
    line-height: 1;
  }
}

.section-title {
  &--stockbroking {
    font-family: $lato-black;
    font-size: clamp(48px, 4.25vw, 4rem);
    font-weight: $font-weight-black;
    color: $black;
    letter-spacing: -0.0275em;
    text-transform: none;
    line-height: 1;
  }
}

// Headings
//---------

.xlarge-heading,
h1 {
  font-family: $lato-black;
  font-size: $font-heading-xlarge;
  font-weight: $font-weight-black;
  color: $black;
  letter-spacing: -0.0175em;
  line-height: 1;

  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
}

.large-heading,
h2 {
  font-family: $lato-black;
  font-size: $font-heading-large;
  font-weight: $font-weight-black;
  color: $black;
  letter-spacing: -0.01em;
  line-height: 1;

  @media screen and (max-width: 767px) {
    font-size: 1.75rem;
  }
}

.medium-heading,
h3 {
  font-family: $lato-bold;
  font-size: $font-heading-medium;
  font-weight: $font-weight-bold;
  color: $black;
  letter-spacing: -0.01em;
  line-height: 1;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.small-heading,
h4 {
  font-family: $lato-bold;
  font-size: $font-heading-small;
  font-weight: $font-weight-bold;
  color: $black;
  letter-spacing: -0.01em;
  line-height: 1;
}

// body text
//----------

p {
  line-height: 1.5625em;
  font-weight: $font-weight-normal;
  letter-spacing: 0;
}

.body-text {
  line-height: 1.5625em;
  font-family: $lato;
  font-weight: $font-weight-normal;
  letter-spacing: 0;
  color: $black;
}

b,
strong,
.body-bold,
h5 {
  font-weight: $font-weight-bold;
  font-family: $lato-bold;
}

.body-large {
  font-size: $font-body-large;
}

.caption,
h6 {
  font-size: $font-body-small;
  font-family: $lato-bold;
  font-weight: $font-weight-bold;
}

.body-med {
  font-size: $font-body-small;
}

.body-small {
  font-size: $font-body-xsmall;
}

// Paragraph line length character limits
//-----------------------------------------
.p-length-sm {
  max-width: $paragraph-small;
}

.p-length-md {
  max-width: $paragraph-medium;
}

.p-length-lg {
  max-width: $paragraph-large;
}

.p-length-xl {
  max-width: $paragraph-extra-large;
}

// Text Align
//-----------------------------------------

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

// List styles
//--------------------------------------------

ol.number-list {
  list-style-position: outside;
  margin-left: 1.5rem;
}

ul {
  padding: 0 1rem;
}

ul.ul-p-0 {
  padding: 0 !important;
}

ul.dot-list {
  list-style: disc;
  list-style-position: outside;
  margin-left: 1.35rem;
}

::marker {
  color: $cyan;
}

ul ul {
  margin-left: 1.2rem;
}

ol .numeric-list::marker {
  color: black !important;
}

.nav-list {
  text-decoration: none;
  border-bottom: 2px solid transparent;

  // click
  &:active {
    border-bottom: 2px solid $cyan;
    color: $cyan;
  }

  //hover
  &:hover {
    border-bottom: 2px solid $cyan;
    color: $cyan;
  }
}

ul.no-bullets {
  list-style-type: none !important;
}
