@import "../foundation/global-settings.scss";

//----------

//1. contact us page
@media screen and (max-width: 599px) {
  .hideCommaOnMobile {
    display: none;
  }

  h2 {
    text-align: left;
    background: transparent;
  }
}

//links
a {
  cursor: pointer;
}

// prducts download cta link

.product-dl > .icon-dl {
  font-size: 1.5rem;
  @media screen and (max-width: 1025px) {
    font-size: 2rem;
  }
}

.product-dl > .text-dl {
  line-height: 1em;
  @media screen and (max-width: 1025px) {
    line-height: 1em;
    padding-bottom: 3px;
  }
}

// text align center left align for mobile

.text-center-md {
  text-align: center;
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

// text align right left align for mobile

.text-right-md {
  text-align: right;
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

//Savings page
//-----------------------------------------------
.savings-animatio-text::before {
  content: "house";
  -webkit-animation: animate infinite 6s;
          animation: animate infinite 6s;
  border-bottom: 5px solid $cyan;
  line-height: 0;
}

@-webkit-keyframes animate {
  0% {
    content: "house";
  }

  50% {
    content: "education";
  }

  75% {
    content: "retirement";
  }
}

@keyframes animate {
  0% {
    content: "house";
  }

  50% {
    content: "education";
  }

  75% {
    content: "retirement";
  }
}

//retirement planning page
//----------------------------------------------
.retirementBorder-Bottom {
  border-bottom: 5px solid $cyan;
}

// all psg funds page
.local-funds-text-mobile {
  @media screen and (max-width: 767px) {
    margin-left: 0 !important;
  }
}

//-----------------------------------
// blockquote
//-----------------------------------

.blockquote {
  font-family: $lato;
  font-size: clamp(24px, 5.75vw, 2rem);
  font-weight: $font-weight-black;
  color: $black;
  letter-spacing: -0.0175em;
  text-transform: none;
  line-height: 0.9;
  &.large {
    font-size: clamp(36px, 5.75vw, 3.25rem);
  }
  &.xlarge {
    font-size: clamp(42px, 5.75vw, 4.25rem);
  }
}

//
// checkbox list
//---------------
ul.checkbox-list {
  list-style: none;
  // list-style-image: url(/assets/images/list-icon/checkbox-icon.svg);
  // list-style-position: outside;
  margin-left: 2rem;

  & li {
    margin-bottom: 1rem;
  }
  & li::before {
    content: url(/assets/images/list-icon/checkbox-icon.svg);
    position: absolute;
    left: 0;
    margin-left: 1.125rem;
    margin-top: 2px;
  }
}

#wrapper {
  display: flex;
}

#one {
  order: 4;
  background-color: gray;
}

#two {
  order: 3;
  background-color: green;
}

#three {
  order: 2;
  background-color: blue;
}

#four {
  order: 1;
  background-color: orange;
}

// sens aricle
//---------------

pre.sens-article {
  overflow-wrap: normal;
  overflow: visible;
  font-family: $lato;
}
// fix innerhtml styling
.inner-content > p {
  color: $white;
}

.text-shadow {
  text-shadow: 0px 2px 1rem $black-30;
}

.heading-left {
  @media screen and (max-width: 767px) {
    text-align: left !important;
  }
}

ul {
  padding: 0 1rem;
}

.pointer {
  cursor: pointer;
}
