@import "../foundation/global-settings.scss";

.tab {
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: $slate-10;
  border-radius: $border-radius-small;
  padding: $spacing-padding-compact;
}

.tab > li {
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  padding: $spacing-padding-tight;
  padding-left: $spacing-padding-small;
  padding-right: $spacing-padding-small;
  display: inline-block;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
  position: relative;
  line-height: normal;
  font-family: $lato-bold;
  //font-weight: $font-weight-bold;
  font-size: $font-body-small;
  transition: $transition-2;
  color: $black;
  text-decoration: none;
  background-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (hover: hover) {
  .tab > li:hover {
    color: $cyan;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid $slate-30;
  }
}

.tab .active {
  color: $cyan;
  text-decoration: none;
  background-color: $white;
  border: 1px solid transparent;
  border-radius: $border-radius-small;
  box-shadow: $shadow-slight-raise;

  a {
    color: $cyan;
  }
}

.tab > li:disabled {
  color: $cyan;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  opacity: 0.6;
}

.table .sort-icon {
  color: $slate;
  transition: $transition-1;
  float: right;
  height: 0;

  &:hover {
    color: $cyan;
  }

  &:active {
    color: $black;
  }
}

//Vertical

.tab.vertical {
  flex-direction: column;
}
