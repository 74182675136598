@import "../foundation/global-settings.scss";
//  Usage:
//  .svg-bg -- this sets the svg image to the default
//  Append this with a unique class thats unique to the section and set the X and Y positioning

//  note: comment the page name and/or section

//-------------------------------------------------------------------------------------------------------

// Default settings
.svg-bg {
  background: url("../../images/background-svg/grey-texture.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

// Default right aligned bg
.svg-bg-r {
  background: url("../../images/background-svg/svg-bg-r.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

// Default left aligned bg
.svg-bg-l {
  background: url("../../images/background-svg/svg-bg-l.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
}

//-------------------------------------------------------------------------------------------------------
// **** new version ****
//-------------------------------------------------------------------------------------------------------
.greybg-left-large {
  background: url("../../images/background-svg/svg-bg-l.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/svg-bg-l-720px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 1367px) {
    background: url("../../images/background-svg/svg-bg-l-480px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.greybg-left {
  background: url("../../images/background-svg/svg-bg-l-720px.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.greybg-left-small {
  background: url("../../images/background-svg/svg-bg-l-720px.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/svg-bg-l-480px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.greybg-right-large-event-webinairs {
  background: url("../../images/background-svg/svg-bg-r.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  height: 410px !important;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/svg-bg-r-720px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    height: 410px !important;
  }

  @media screen and (max-width: 1367px) {
    background: url("../../images/background-svg/svg-bg-r-480px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    height: 410px !important;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

//-------------------------------------------------------------------------------------------------------
// Right aligned bg
//-------------------------------------------------------------------------------------------------------
.greybg-right-large {
  background: url("../../images/background-svg/svg-bg-r.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/svg-bg-r-720px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 1367px) {
    background: url("../../images/background-svg/svg-bg-r-480px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.greybg-right {
  background: url("../../images/background-svg/svg-bg-r-720px.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.greybg-right-small {
  background: url("../../images/background-svg/svg-bg-r-720px.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;

  @media screen and (max-width: 1441px) {
    background: url("../../images/background-svg/svg-bg-r-480px.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.slateBg-mobile {
  @media screen and (max-width: 767px) {
    background-color: $slate-10;
  }
}

//-------------------------------------------------------------------------------------------------------
//
// todo: delete all the useless css below
//
//-------------------------------------------------------------------------------------------------------

//1. za - home page
.svg-bg.global--home {
  background-position: 60vw 0;
  @media screen and (max-width: 599px) {
    background-size: 0px;
  }
}

.svg-bg-r.reviews-bg {
  background-position: 20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: 25vw 0;
  }

  @media screen and (max-width: 1025px) {
    background: none;
  }
}

.svg-bg-l.financialAdvice {
  background-position: -20vw 0;
  @media screen and (max-width: 1441px) {
    background-position: -40vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -45vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -50vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-image: none;
    background-color: $slate-10;
  }
}

.svg-bg-l.far {
  background-position: 10vw 0;
  @media screen and (max-width: 1441px) {
    background-position: 20vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: 22.5vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: 25vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-image: none;
    background-color: $slate-10;
  }
}

.svg-bg-r.options {
  background-position: 8vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: 25vw 0;
  }

  @media screen and (max-width: 1025px) {
    background: none;
  }
}

// how to invest
.svg-bg-r.how-to-i {
  background-position: 200px 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: right 0;
    background-size: contain;
  }
  @media screen and (max-width: 599px) {
    background-size: 360px;
  }
}

.svg-bg-l.how-to-i {
  background-position: -200px 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: left 0;
    background-size: contain;
  }
  @media screen and (max-width: 599px) {
    background-size: 360px;
  }
}

//Global - all-funds page
//-------------------------------------------------------------------------------------------------------
.svg-bg-l.funds-l {
  background-position: -20vw 0;
  @media screen and (max-width: 1441px) {
    background-position: -40vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -45vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -50vw 0;
  }

  @media screen and (max-width: 1025px) {
    background: none;
  }
}

.svg-bg-r.funds-r {
  background-position: 20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: 25vw 0;
  }

  @media screen and (max-width: 1025px) {
    background: none;
  }
}

//3. Global - news
//-------------------------------------------------------------------------------------------------------
.svg-bg.global--news {
  background-position: 60vw 0;
  @media screen and (max-width: 767px) {
    background-size: 204px;
  }
}

//4. Global - investment product
//-------------------------------------------------------------------------------------------------------
.svg-bg.global--i-product {
  background-position: 60vw 0;
  @media screen and (max-width: 599px) {
    background-size: 520px;
  }
}

//Global fund performance section on funds pages
//-------------------------------------------------------------------------------------------------------
.svg-bg-l.details--fundP {
  background-position: -20vw 0;
  @media screen and (max-width: 1441px) {
    background-position: -30vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -35vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -40vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: -60vw 0;
  }
  @media screen and (max-width: 960px) {
    background: none;
    background-color: $slate-10;
  }
}

//5. za about us
//--------------------------------------------------------------
.svg-bg-l.philosophy-and-process {
  background-position: -1vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -3vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -4vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -7vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: -19vw 0;
  }

  @media screen and (max-width: 767px) {
    background-position: 0 0;
  }
}

.svg-bg.aboutTeam {
  background-position: 60vw 0;
  @media screen and (max-width: 599px) {
    background-size: 496px;
  }
}

//6. contact us
//------------------------------------------------------------------
.svg-bg.contactUs {
  background-position: 0vw 0;
}

.div-line {
  border-top: 0.5rem solid $cyan;
  transform: translate(-40%, 1.75rem);
  z-index: 10;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

// fund fact sheet

.svg-bg-l.fund--factSheets {
  background-position: -20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -30vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -35vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -40vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: -60vw 0;
  }
}

//----------------------------------------------------------------------------------
// Daliy investment update
//----------------------------------------------------------------------------------

.svg-bg-l.diu-bg {
  background-position: -20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -20vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -25vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -30vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: -30vw 0;
  }
}

.svg-bg-r.diu-bg {
  background-position: 20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: 20vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: 25vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: 30vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: 30vw 0;
  }
}

//------------------------------------------------------
// save And Invest card
//------------------------------------------------------

.svg-bg-l.savings {
  background-position: -15vw 0;
  background-size: cover;
}

.svg-bg.Investments {
  background-position: 60vw 0;
  @media screen and (max-width: 599px) {
    background-size: 496px;
  }
}

.svg-bg-l.retirement {
  background-position: -15vw 0;
  background-size: cover;
}

.svg-bg.stockbroking {
  background-position: 60vw 0;
}

.svg-bg-l.educate-yourself-section {
  background-position: -15vw 0;
  background-size: cover;
}
// all psg funds page
.local-funds {
  background-position: -20vw 0;
  @media screen and (max-width: 1441px) {
    background-position: -40vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -45vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -50vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-image: none;
    background-color: $slate-10;
  }
}

.global-funds {
  background-position: 60vw 0;
}

//stockbroking page
//-----------------------

.svg-bg-r.stock-b {
  background-position: 20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: 20vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: 25vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: 30vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: 30vw 0;
  }
}

.svg-bg-l.stock-br {
  background-position: -20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -20vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -45vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -50vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-position: -60vw 0;
  }
}

.svg-bg-l.stock-br2 {
  background-position: -20vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -20vw 0;
  }

  @media screen and (max-width: 1367px) {
    background-position: -45vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -50vw 0;
  }

  @media screen and (max-width: 1025px) {
    background-size: 0;
  }
}

.svg-bg-l.retirement-fund {
  background-position: -22vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -27vw 0;
  }

  @media screen and (max-width: 1281px) {
    background-position: -40vw 0;
  }
}

//adviser-profile
//---------------------------------------------------

.about-me-section {
  background-position: 69vw 0;
  @media screen and (max-width: 599px) {
    background-position: 49vw 0;
  }
}

//insurance
.For-your-things-card {
  background-position: -15vw 0;
  background-size: cover;
}

.svg-bg.for-yourself {
  background-position: 60vw 0;
}

.for-your-employees {
  background-position: -15vw 0;
  background-size: cover;
}

.for-your-business {
  background-position: 60vw 0;
}

// for your employees page

.for-your--employees {
  background-position: -9vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -17vw 0;
  }
}

.svg-bg.group-risk-benefits {
  background-position: 60vw 0;
  height: 546px;

  @media screen and (max-width: 767px) {
    height: 848px;
  }
}

.business-insurance {
  background-position: -9vw 0;
  background-size: cover;
  @media screen and (max-width: 1441px) {
    background-position: -17vw 0;
  }
}

.svg-bg.specialist-insurance {
  background-position: 60vw 0;
  height: 546px;

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.svg-bg-l.contact--us {
  background-position: 46vw 0;

  @media screen and (max-width: 767px) {
    background: none;
  }
}
