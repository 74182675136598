@import "../foundation/global-settings.scss";

//1. contact us page
.input-bottom-border {
    border-bottom: 4px solid $cyan;
    border-left: none;
    border-top: none;
    border-right: none;
    width: 100%;
    background: transparent;
    font-size: 22px;
    padding: 0.25rem 0.5rem;
    outline: none;
    @media screen and (max-width: 599px) {
        width: 100% !important;
        margin-bottom: 1.5rem;
        background: transparent;
    }
}

.select-bottom-border {
    border-bottom: 4px solid $cyan;
    border-left: none;
    border-top: none;
    border-right: none;
    width: 25%;
    background: transparent;
    font-size: 22px;
    padding: 0.25rem 0.5rem;
    outline: none;
    @media screen and (max-width: 599px) {
        width: 100% !important;
        margin-bottom: 1.5rem;
        background: transparent;
    }
}

.contactUsInput {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(127, 127, 127, 0.3);
}

.form-control.is-invalid{
    border-color:  rgba(127, 127, 127, 0.3)ne !important;
    background-image: none !important;
}
