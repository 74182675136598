@import "../foundation/global-settings.scss";

// Tooltips

.tooltip {
    display: inline;
    position: relative;
  }

  .tooltip:hover {
    text-decoration: none;
  }

  .tooltip:hover:after {
    background: $black;
    border-radius: .5rem;
    bottom: -2.3rem;
    font-family: $font-family-1;
    font-size:  $font-body-xsmall;
    color: $white;
    content: attr(data-title);
    display: block;
    padding: .3rem 1rem;
    position: absolute;
    text-shadow: 0 1px 0 #000;
    white-space: nowrap;
    z-index: 99;
  }

  .tooltip:hover:before {
    position: absolute;
    bottom: -1.2rem;
    left: 50%;
    height: 0.8rem;
    width: 0.8rem;
    background-color: $black;
    border-radius: 0.1rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    display: block;
    z-index: 98;
  }

  .tooltip-top:hover:after {
    bottom: auto;
    top: -2.3rem;
  }

  .tooltip-top:hover:before {
    bottom: auto;
    top: -1.2rem;
  }

