//header
//-------------------------------------------------

.toggle-name {
  font-family: $lato-bold;
  text-transform: none;
  margin-bottom: 0;
}

a .prev-button,
.next-button {
  transition: $transition-2;
  cursor: pointer;
  color: $black-80;
  text-decoration: none;
  &:hover {
    color: $cyan;
    text-decoration: none;
  }
}

// flex rows
//---------------------------------------------------

.flexedApart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.flexedAround {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

// calendar heading section
//------------------------------------------------------

.calendar-header {
  padding: $spacing-padding-tight $spacing-padding-small;
}

.next-button {
  font-size: $font-heading-small;
}
.prev-button {
  font-size: $font-heading-small;
  margin-right: $spacing-margin-medium;
}

// days of the week
//--------------------------------------------------------

.days-of-week {
  padding: $spacing-padding-compact;
  border-bottom: 1px solid $slate-30;
}

.days-of-week p {
  color: $slate;
  font-family: $lato-bold;
}

.day-block {
  text-align: center;
  padding: $spacing-padding-compact;
  width: 40px;
  height: 24px;
  font-family: $lato-bold;
}

// dates section
//--------------------------------------------------

.calendar-body {
  padding: $spacing-padding-compact;
}

.date-block {
  background: transparent;
  border-radius: 25px;
  transition: $transition-2;
  height: 38px;
  width: 38px;
  margin: 1px;

  &:hover {
    background: $cyan;
  }
}

.date-block p {
  padding: 0.7rem 0.5rem;
  color: $black-80;
  text-align: center;
  transition: $transition-2;
  line-height: normal;
  font-size: $font-body-small;
  font-family: $lato-bold;
  margin-bottom: 0;
}

.date-block:hover > p {
  color: $white;
  cursor: pointer;
}

.date-block.inactive {
  opacity: 0;
  &:hover {
    opacity: 0;
  }
}

// months
///------------------------------------------------------

.months-body {
  border-top: 1px solid $slate-30;
  padding: $spacing-padding-compact;
}

#month {
  padding: $spacing-padding-compact 0;
}

.month-block p {
  background: transparent;
  border-radius: 25px;
  transition: $transition-2;
  padding: 0.5rem 1rem;
  font-family: $lato-bold;
  font-size: $font-body-small;
  width: 70px;
  text-align: center;

  &:hover {
    background: $cyan;
    color: $white;
    cursor: pointer;
  }
}

// years
///------------------------------------------------------

.years-body {
  border-top: 1px solid $slate-30;
  padding: $spacing-padding-compact;
}

#year {
  padding: $spacing-padding-compact 0;
}

.year-block p {
  background: transparent;
  border-radius: 25px;
  transition: $transition-2;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-family: $lato-bold;
  font-size: $font-body-small;
  width: 70px;
  text-align: center;

  &:hover {
    background: $cyan;
    color: $white;
    cursor: pointer;
  }
}

//---------------------------------------------------------------------

.date {
  z-index: 10000;
  width: 255px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: absolute;
  display: none;
  top: calc(100% + 10px);
  left: 0;
}
.date.isActive {
  display: block;
}
.month-container {
  background: #0a95ff;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
.week-container {
  background: #ebf6ff;
  padding: 0 5px;
}
.month {
  color: #fff;
}
.week,
.day {
  width: 2.5em;
  padding: 0.625em 0;
  text-align: center;
}
.day-container {
  padding: 5px;
  background: #fff;
}
.day {
  border-radius: 100%;
}
.day:hover {
  background: #fafafa;
}
.day.isSelected {
  background: #0a95ff;
  color: #fff;
}
.blue--dark {
  color: #00457a;
}

//--------------------------
.force-show {
  width: 300px;
  height: 310px;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(405px, 415px, 0px);
}
