// Global style settings
// --------------------
//
//     *This file uses rem for spacing and font size instead of px. *
//
//      1rem = 16px @ 100% html font size
//
//      Pixel to em/rem converter: https://www.w3schools.com/tags/ref_pxtoemconversion.asp
//
//  Table of Contents:
//   1. Browser reset
//   2. Html & body defaults
//   3. Colours
//      3.1 Primary colour palette
//      3.2 Secondary colour palette
//      3.3 Semantic colour palette
//      3.4 Gradient colour
//   4. Text
//      4.1 Text Family
//      4.2 Body Font Size
//      4.3 Heading Font Size
//      4.4 Title Font Size
//      4.5 Text Line Height
//      4.6 Text Weight
//      4.7 Text line length
//   5. Borders
//      5.1 Borders Thickness
//      5.2 Borders Radius
//   6. Shadows
//      6.1 Elevation Shadows
//      6.2 Colour Shadows
//   7. Spacing
//      7.1 Spacing Margin
//      7.2 Spacing Padding
//   8. Transitions

// 0. Imports
//------------------------------------------------------------------------

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@900&display=swap");

@font-face {
  font-family: "Lato-black";
  src: url("/assets/fonts/lato/Lato-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato-black-i";
  src: url("/assets/fonts/lato/Lato-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Lato-bold";
  src: url("/assets/fonts/lato/Lato-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato-bold-i";
  src: url("/assets/fonts/lato/Lato-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/lato/Lato-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato-i";
  src: url("/assets/fonts/lato/Lato-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("/assets/fonts/source-serif/SourceSerifPro-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

$source-serif: "Source Serif Pro";
$lato-black: "Lato-black";
$lato-black-i: "Lato-black-i";
$lato-bold: "Lato-bold";
$lato-bold-i: "Lato-bold-i";
$lato: "Lato";
$lato-i: "Lato-i";

// 1. Browser reset
//------------------------------------------------------------------------

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// 2. html & body defaults
//------------------------------------------------------------------------

html {
  font-size: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Lato";
  font-weight: 400;
  /* font-size: 16px; */
  box-sizing: border-box;
  color: #1e1e1e;

  //ToDo: Remove
  // background-color: rgba(255, 255, 255, 0.1);
}

// 3. Colours
//------------------------------------------------------------------------

// 3.1 primary colour palette

// Cyan

$cyan: rgba(0, 175, 239, 1);
$cyan-80: rgba(0, 175, 239, 0.8);
$cyan-50: rgba(0, 175, 239, 0.5);
$cyan-30: rgba(0, 175, 239, 0.3);
$cyan-10: rgba(0, 175, 239, 0.1);

// Black

$black: rgba(30, 30, 30, 1);
$black-80: rgba(30, 30, 30, 0.8);
$black-50: rgba(30, 30, 30, 0.5);
$black-30: rgba(30, 30, 30, 0.3);
$black-10: rgba(30, 30, 30, 0.1);

// Dark Black

$dark-black: rgba(0, 0, 0, 1);
$dark-black-80: rgba(0, 0, 0, 0.8);
$dark-black-50: rgba(0, 0, 0, 0.5);
$dark-black-30: rgba(0, 0, 0, 0.3);
$dark-black-10: rgba(0, 0, 0, 0.1);

// Grey

$grey: rgba(57, 57, 57, 1);
$grey-80: rgba(57, 57, 57, 0.8);
$grey-50: rgba(57, 57, 57, 0.5);
$grey-30: rgba(57, 57, 57, 0.3);
$grey-10: rgba(57, 57, 57, 0.1);

// Slate

$slate: rgba(127, 127, 127, 1);
$slate-80: rgba(127, 127, 127, 0.8);
$slate-50: rgba(127, 127, 127, 0.5);
$slate-30: rgba(127, 127, 127, 0.3);
$slate-10: rgba(127, 127, 127, 0.1);
$slate-5: rgb(241 235 235 / 30%);

// Gold

$gold: rgba(158, 145, 111, 1);
$gold-80: rgba(158, 145, 111, 0.8);
$gold-50: rgba(158, 145, 111, 0.5);
$gold-30: rgba(158, 145, 111, 0.3);
$gold-10: rgba(158, 145, 111, 0.1);

// White

$white: rgba(255, 255, 255, 1);
$white-80: rgba(255, 255, 255, 0.8);
$white-50: rgba(255, 255, 255, 0.5);
$white-30: rgba(255, 255, 255, 0.3);
$white-10: rgba(255, 255, 255, 0.1);

// 3.2 Secondary colour palette

//  Blue

$blue-20: #004986;
$blue: rgba(0, 73, 134, 1);
$blue-80: rgba(0, 73, 134, 0.8);
$blue-50: rgba(0, 73, 134, 0.5);
$blue-30: rgba(0, 73, 134, 0.3);
$blue-10: rgba(0, 73, 134, 0.1);
$blue-20: #004986;

// Dark Blue

$dark-blue: rgba(53, 85, 131, 1);
$dark-blue-80: rgba(53, 85, 131, 0.8);
$dark-blue-50: rgba(53, 85, 131, 0.5);
$dark-blue-30: rgba(53, 85, 131, 0.3);
$dark-blue-10: rgba(99, 136, 180, 1);

// Green

$green-20: #00aa6f;
$green: rgba(0, 170, 111, 1);
$green-80: rgba(0, 170, 111, 0.8);
$green-50: rgba(0, 170, 111, 0.5);
$green-30: rgba(0, 170, 111, 0.3);
$green-10: rgba(0, 170, 111, 0.1);
$green-20: #00aa6f;

// Yellow

$yellow: rgba(255, 209, 0, 1);
$yellow-80: rgba(255, 209, 0, 0.8);
$yellow-50: rgba(255, 209, 0, 0.5);
$yellow-30: rgba(255, 209, 0, 0.3);
$yellow-10: rgba(255, 209, 0, 0.1);

// Purple

$purple: rgba(119, 43, 137, 1);
$purple-80: rgba(119, 43, 137, 0.8);
$purple-50: rgba(119, 43, 137, 0.5);
$purple-30: rgba(119, 43, 137, 0.3);
$purple-10: rgba(119, 43, 137, 0.1);

// Red

$red: rgba(208, 52, 56, 1);
$red-80: rgba(208, 52, 56, 0.8);
$red-50: rgba(208, 52, 56, 0.5);
$red-30: rgba(208, 52, 56, 0.3);
$red-10: rgba(208, 52, 56, 0.1);

//  3.3 Semantic colour palette

$error: $red;
$success: $green;
$warning: $yellow;

//  3.4 Gradient colour

$cyan-gradient: linear-gradient(90deg, #00afef 0.87%, #2bd9fe 100%);

//  4. Text
//------------------------------------------------------------------------

//  4.1 Text Family

$font-family-1: "Lato", sans-serif;
$font-family-2: "Source Serif Pro", serif;

//  4.2 body font Size

$font-body-xsmall: 0.75rem;
$font-body-small: 0.875rem;
$font-body-medium: 1rem;
$font-body-large: 1.125rem;

//  4.3 Heading font Size

$font-heading-small: 1.25rem;
$font-heading-medium: 1.75rem;
$font-heading-large: 2rem;
$font-heading-larger: 2.5rem;
$font-heading-xlarge: 2.875rem;

//  4.4 title font Size

$font-title-xxlarge: 4.5rem; // source serif fonts
$font-title-xxxlarge: 6.875rem;

//  4.5 Text Line Height

$font-line-height-small: 1.2;
$font-line-height-normal: normal;
$font-line-height-large: 2;

//  4.6 Text Weight

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

//  4.7 Text line length

$paragraph-small: 432px;
$paragraph-medium: 580px;
$paragraph-large: 706px;
$paragraph-extra-large: 1024px;

//  5. Borders
//------------------------------------------------------------------------

//  5.1 Border Thickness

$border-xs: 0.5px;
$border-sm: 1px;

//  5.2 Border Radius

$border-radius-small: 0.5rem; // - interactive Elements ie: buttons
$border-radius-medium: 1rem; // - Surface Elelments - Cards & Containers
$border-radius-large: 1.75rem;

//  6. Shadows
//------------------------------------------------------------------------

//  6.1 Elevation Shadows

$shadow-slight-raise: 0px 1px 0.5rem 0rem rgba(0, 0, 0, 0.1);
$shadow-noticeable-raise: 0px 2px 1rem -0.25rem rgba(0, 0, 0, 0.15);
$shadow-significant-raise: 0px 3px 2rem -0.5rem rgba(0, 0, 0, 0.2);
$shadow-extreme-raise: 0px 5px 3rem -0.5rem rgba(0, 0, 0, 0.2);

//  6.2 Colour Shadows

$shadow-colour-cyan: 0px 0.5rem 1rem -0.5rem rgba($cyan, 0.25);
$shadow-colour-cyan-hover: 0px 1rem 1rem -0.5rem rgba($cyan, 0.3);

//  7. Spacing
//------------------------------------------------------------------------

//  7.1 Spacing Margin

$spacing-margin-close: 0.75rem;
$spacing-margin-medium: 1.25rem;
$spacing-margin-far: 2rem;

//  7.1 Spacing Padding

$spacing-padding-compact: 0.25rem;
$spacing-padding-tight: 0.5rem;
$spacing-padding-small: 0.75rem;
$spacing-padding-medium: 1rem;
$spacing-padding-roomy: 1.75rem;

//  8. Transitions
//------------------------------------------------------------------------

$transition-1: all 0.1s ease-out;
$transition-2: all 0.2s ease-out;
$transition-3: all 0.3s ease-out;
