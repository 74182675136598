@import "../foundation/global-settings.scss";
//----------------------------------------------

.remove-form-spacing > .form-field-basic {
    margin-bottom: 0px;
}

.remove-range-spacing > .container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-bottom: 1rem;
}

.slider-value {
    background-color: $slate-10;
    margin-bottom: 0px;
    padding: 4px 8px;
    border-radius: 8px;
    margin-left: 8px;
}

.calcValue {
    font-family: $lato;
    font-size: clamp(32px, 4vw, 32px);
    line-height: 1.1;
}

.calcRow {
    margin-top: -4.25rem;
}

.slide-label {
    font-size: 0.875rem;
    font-weight: 700;
    color: $slate;
    margin-bottom: -0.25rem;
}

.retirementCalc-hero{
    height: auto;
    background-image: linear-gradient(235deg, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 1) 100%),
    url("../../images/home-page/calc-retirement.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media screen and (max-width: 768px) {
        height: auto;
        background-position: center;
    }
}


.goalCalc-hero{
    height: auto;
    background-image: linear-gradient(235deg, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 1) 100%),
    url("../../images/home-page/calc-goal.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media screen and (max-width: 768px) {
        height: auto;
        background-position: center;
    }
}
  

.educationlCalc-hero {
    height: auto;
    background-image: linear-gradient(235deg, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 1) 100%),
    url("../../images/home-page/saveForAneducation.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media screen and (max-width: 768px) {
        height: auto;
        background-position: right;
    }
}

