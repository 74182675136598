@import "./global-settings.scss";

//  Animation
//  -----------------------------

$fluidcurve: cubic-bezier(0.215, 0.610, 0.355, 1.000);

//  Reveal
//  ------

@keyframes animationReveal {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 1;
    }
}


.animation-reveal {
    * {
        animation: animationReveal 500ms $fluidcurve both;
  
        @for $i from 1 through 50 {
          &:nth-child(#{$i}) {
            animation-delay: 100ms * $i;
          }
        }
    }
}

//  Fade
//  ------

@keyframes animationFade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
}


.animation-fade {
    * {
        animation: animationFade 500ms $fluidcurve both;
  
        @for $i from 1 through 100 {
          &:nth-child(#{$i}) {
            animation-delay: 80ms * $i;
          }
        }
    }
}