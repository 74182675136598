@import "../foundation/global-settings.scss";

.slider {
  background: linear-gradient(to right, $cyan 0%, $cyan 0%, $slate-30 0%, $slate-30 0%);
  border-radius: $border-radius-small;
  border: 1px solid transparent;
  height: 3px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.slider::-webkit-slider-thumb {
  width: 24px;
  height: 24px;
  background: $black;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
  -webkit-appearance: none;
  //cursor: ew-resize;
  margin-top: 0px;
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: $black;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
}

.multi-slider {
  box-sizing: border-box;
  appearance: none;
  height: 3px;
  width: 95%;
  margin: 0;
  padding: 0 2px;
  overflow: none;
  border: 0;
  border-radius: 1px;
  outline: none;
  background: linear-gradient(to right, $cyan 0%, $cyan 0%, $slate-30 0%, $slate-30 0%);
  background-size: 100% 2px;
  pointer-events: none;

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    border-radius: $border-radius-small;
    background-color: $black;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 13px;
      left: 100%;
      width: 100%;
      height: 2px;
    }

  }
}

.multi-range {
  position: relative;
  height: 50px;

  .multi-slider {
    position: absolute;

    &:nth-child(1) {
      &::-webkit-slider-thumb::before {
        background-color: linear-gradient(to right, $cyan 0%, $cyan 0%, $slate-30 0%, $slate-30 0%);
      }
    }

    &:nth-child(2) {
      background: none;

      &::-webkit-slider-thumb::before {
        background-color: linear-gradient(to right, $cyan 0%, $cyan 0%, $slate-30 0%, $slate-30 0%);
      }
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "item-1 Item-2 Item-3";
}

.item-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: ". . ."". . .";
  grid-area: item-1;
}

.Item-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: ". Item- ."". . .";
  grid-area: Item-2;
}

.Item- {
  grid-area: Item-;
}

.Item-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: ". . item"". . .";
  grid-area: Item-3;
}

.item {
  grid-area: item;
}
