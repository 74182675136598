@import "../foundation/global-settings.scss";
//Global all funds tables
//---------------------------------------------------------------------------------

.table-card {
    background: $white;
    border-radius: $border-radius-large;
    padding: 3rem;
    @media screen and (max-width: 599px) {
        padding: 1.25rem 1rem;
        border-radius: 1rem;
    }
}

//---------------------------------------------------------------------------------

.table-bordered,
table {
    border: 1px solid $slate-30;
}

.table-hover tbody tr:hover {
    background-color: $slate-10;
    transition: $transition-2;
}

tfoot {
    background-color: $slate-10;
}

.table td {
    vertical-align: middle;
}

.table-bordered thead th {
    border-bottom-width: 1px;
}

.tab-nowrap > .tab {
    flex-wrap: nowrap !important;
}

// overview fund table colums
//---------------------------

.overview-1 {
    width: 10%;
    min-width: 110px;
    @media screen and (max-width: 768px) {
        min-width: 105px;
    }
}

.overview-2 {
    width: 35%;
    min-width: 400px;
    @media screen and (max-width: 768px) {
        min-width: 230px;
    }
}

.overview-3 {
    width: 10%;
    min-width: 84px;
    @media screen and (max-width: 768px) {
        min-width: 80px;
    }
}

.overview-4 {
    width: 15%;
    min-width: 84px;
    @media screen and (max-width: 768px) {
        min-width: 80px;
    }
}

.overview-5 {
    width: 15%;
    min-width: 150px;
    @media screen and (max-width: 768px) {
        min-width: 135px;
    }
}

.overview-6 {
    width: 15%;
    min-width: 143px;
    @media screen and (max-width: 768px) {
        min-width: 130px;
    }
}

// price and perforamnce
//---------------------------------------------

.pP-1 {
    width: 44%;
    min-width: 446px;
    @media screen and (max-width: 768px) {
        min-width: 210px;
    }
}

.pP-2 {
    width: 18%;
    min-width: 183px;
    @media screen and (max-width: 768px) {
        min-width: 156px;
    }
}

.pP-3 {
    width: auto;
}

.pP-4 {
    width: 9%;
    min-width: 91px;
    @media screen and (max-width: 768px) {
        min-width: 80px;
    }
}

.pP-5 {
    width: 9%;
    min-width: 91px;
    @media screen and (max-width: 768px) {
        min-width: 80px;
    }
}

.pP-6 {
    width: 9%;
    min-width: 91px;
    @media screen and (max-width: 768px) {
        min-width: 80px;
    }
}

.pP-7 {
    width: 11%;
    min-width: 111px;
    @media screen and (max-width: 768px) {
        min-width: 100px;
    }
}

// sens share data
//----------------------------------------------------------

.shareFeed-table {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 450px;
    padding-right: 0.25rem;

    @media (hover: hover) {
        &:hover {
            overflow-y: auto;
        }
    }
    @media screen and (max-width: 1023px) {
        overflow-y: auto;
        max-height: 360px;
    }
}

//------------

table {
    width: 100%;

    th,
    td {
        padding: 12px;
    }
}
