@import "../foundation/global-settings.scss";

// removes browser focus
//------------------------------------------------------------------------
button:focus {
  outline: none;
}

// Input File Upload
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Input File Upload- Icon
//------------------------------------------------------------------------
.button.file-upload-icon,
.button.file-upload-icon:link,
.button.file-upload-icon:visited {
  background-color: $slate-10;
  padding: $spacing-padding-tight;

  //  hover
  &:hover {
    background-color: $slate-30;
  }

  // click
  &:active {
    background-color: $slate-30;
    border: 1px solid $cyan;
  }
}

.file-upload__input {
  display: none;

  .icon {
    color: $cyan;
  }
}

.file-upload__button {
  cursor: pointer;
  display: inline-block;
  text-transform: none;
  text-decoration: none;
  padding: $spacing-padding-small;
  border-radius: $border-radius-small;
  background-color: white;
  border: 1px solid $slate-10;
  margin: 0;
  min-height: 44px;
  max-height: 44px;
  vertical-align: middle;
  text-align: center;

  &:active {
    cursor: pointer;
    border: 1px solid $slate-30;
  }

  .icon {
    float: left;
  }

  p {
    font-weight: $font-weight-bold;
    font-size: $font-body-small;
    line-height: normal;
    margin: 0;
    transform: translateX(-0.5rem);
  }
}

.file-upload__label {
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  padding: $spacing-padding-small;
  border-radius: $border-radius-small;
  font-weight: $font-weight-bold;
  font-size: $font-body-small;
  border: 1px solid transparent;
  background-color: $slate-10;
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  max-height: 44px;

  .icon {
    float: left;
    margin-right: 0.5rem;
  }
}

.container-drop {
  position: relative;
  margin-bottom: 5px;
  text-transform: none;
  text-align: center;
  padding: $spacing-padding-roomy;
  border-radius: $border-radius-small;
  border: 1px dashed $slate-30;
  font-weight: $font-weight-bold;
  font-size: $font-body-small;
  margin-bottom: 5px;
  width: 100%;
  height: 100%;

  & input {
    display: none;
    opacity: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:active {
    border: 1px solid $cyan;
  }
}

.fileover {
  border: 1px dashed $cyan;
}
