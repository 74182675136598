@import "../foundation/global-settings.scss";

//  Stepper Content
//  --------------------------------------------

.stepper-content-container {
    overflow-x: hidden;
}

.step-content {
    background-color: coral;
    position: relative;
    -webkit-transition: all .3s ease-in-out; 
    -moz-transition: all .3s ease-in-out; 
    -ms-transition: all .3s ease-in-out; 
    -o-transition: all .3s ease-in-out; 
    transition: all .3s ease-in-out; 
}

.step-content.left {
    left: -100%;
}

.right {
    left: 100%;
}

//  Stepper Container

.stepper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

//  Heading

.stepper-container .small-heading {
    margin-bottom: 0px;
}

.stepper-container .body-small {
    margin-bottom: 0px;
    color: $black-80;
}

//  Stepper Progress
//  ----------------------------------------------

//  Step Ring

.stepper-progress .ring {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-width: 4;
    fill: transparent;
}

.stepper-progress .ring.background {
    stroke: $slate-10;
}

.stepper-progress .ring.current {
    stroke: $slate-30;
}

.stepper-progress .ring.next {
    stroke: $cyan;
}

.stepper-progress .ring.done {
    fill: $cyan;
}

//  Step Text

.stepper-progress .text {
    fill: $black-80;
    text-anchor: middle;
}

.stepper-progress .text.done {
    fill: $white;
}

