@import "../foundation/global-settings.scss";



// font sizes
//  ---------------------------------------

th {
  @media screen and (max-width: 960px) {
    font-size: $font-body-small !important;
  }
}

td {
  @media screen and (max-width: 960px) {
    font-size: $font-body-xsmall !important;
  }
}
