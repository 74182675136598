@import "../foundation/global-settings.scss";

.bacground-two-colors {
  background: linear-gradient(to bottom, rgba(0, 175, 239, 0.05) 60%, rgba(241, 235, 235, 0.3) 40%);
}

.two-colors-bg {
  background: linear-gradient(
    to bottom,
    rgba(127, 127, 127, 0.1),
    rgba(127, 127, 127, 0.1) 15%,
    $white 15%,
    $white 85%,
    $white 85%
  );
}

.back-card {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  height: 220px !important;
  transform: translate(0, -100%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0.6) 0%, rgba(30, 30, 30, 0.8) 50%, rgba(30, 30, 30, 1) 100%);
}

.front-card {
  opacity: unset !important;
  position: absolute;
  transform: translate(0, -100%);
  -ms-transform: translate(-50%, -50%);
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(30, 30, 30, 0.1) 55%,
    rgba(30, 30, 30, 0.2) 60%,
    rgba(30, 30, 30, 1) 100%
  );
}

.back-card,
.front-card {
  text-align: center;
  border-radius: 1rem;
  width: 200px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 1rem;
}

.hover--card:hover .front-card {
  display: none;
}

.team-hoverCard-bg-img {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center btop;
  background-size: cover;
  border-radius: $border-radius-medium;
  box-shadow: $shadow-noticeable-raise;
  height: 220px;
  cursor: pointer;
  width: 200px;
}
.branchTeamContainer:hover .front-card {
  opacity: 0.3;
}

.branchTeamContainer:hover .back-card {
  opacity: 1;
}
