@import "../foundation/global-settings.scss";

// Animations
// ----------------------------------------
//animation slide-bottom

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

// removes browser focus
//------------------------------------------------------------------------
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

// hides browser autofill styles
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 3rem white inset !important;
}

// add space to number input arrows
//------------------------------------------------------------------------

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: $spacing-padding-tight;
  opacity: 1;
}

// Simple Inputs
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Basic Input
//------------------------------------------------------------------------

input {
  transition: all 0.2s;
  background-color: transparent;
}

textarea.input-basic {
  transition: all 0.2s;
  resize: none;
}

.input-basic {
  width: 45px;
  padding: $spacing-padding-tight;
  border-radius: $border-radius-small;
  border: 1px solid $slate-30;

  &:hover {
    border: 1px solid $black;
  }

  &:focus {
    border: 1px solid $cyan;
  }
}

// Label For Input
//------------------------------------------------------------------------

label {
  margin-bottom: $spacing-padding-compact;
  margin-left: $spacing-padding-tight;
}

// Validation
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Text Validation

.validation-text {
  margin-top: 0.1rem;
  margin-left: $spacing-padding-tight;
  position: absolute;
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) both;
  animation: slide-bottom 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) both;
}

.required-text {
  margin-top: $spacing-padding-medium;
  line-height: 0.2;
  margin-left: $spacing-padding-compact;
}

// Input Validation

.error-input {
  border: 1px solid $error;
}

.warning-input {
  border: 1px solid $warning;
}

.success-input {
  border: 1px solid $success;
}

// Prefix & Suffix
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Prefix

.input-prefix {
  position: relative;
}
.input-prefix input {
  padding-left: 22px;
}
.input-prefix span {
  position: absolute;
  pointer-events: none;
  display: inline;
  padding: $spacing-padding-tight;
}

// Button Prefix

.input-button-prefix {
  position: relative;
}
.input-button-prefix input {
  padding-left: 60px;
}
.input-button-prefix span {
  position: absolute;
  cursor: pointer;
  display: inline;
  padding: $spacing-padding-compact;
}

// Suffix

.input-suffix {
  position: relative;
}
.input-suffix input {
  padding-right: 30px;
}
.input-suffix span {
  position: absolute;
  right: 0px;
  font-size: $font-heading-small;
  padding: 0.22rem;
  cursor: pointer;
  padding-right: $spacing-padding-small;
}

.input-suffix-not-clickable {
  pointer-events: none;
}

// Selects
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Dropdown
//------------------------------------------------------------------------

.dropdown-select-container {
  cursor: pointer;
  position: relative;

  & select {
    display: none;
  }

  & .suffix {
    margin-left: 0;
  }
}

.dropdown-menu {
  border: none !important;
  border-radius: $border-radius-small !important;
  padding: $spacing-padding-compact !important;
  overflow-y: hidden;
  width: 100% !important;
  z-index: 9999;

  &.max-height {
    max-height: 235px;
    margin-top: 44px;
  }

  &.fit-height {
    margin-top: -42px;
  }

  &.offset-top {
    margin-top: 70px;
  }
}

.dropdown-button {
  text-align: left;
  background: transparent;
  color: $black-80;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;
  width: 100%;
  border-radius: $border-radius-small !important;
  color: $black;

  //hover
  &:hover {
    color: $black;
    border: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black-80;
    border: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    border: 1px solid $black-10;
    opacity: 0.6;
  }
}

.dropdown-button-active {
  color: $black-80;
  border: 1px solid $cyan;
}

// scroll bars
//------------------------------------------------------------------------

/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
  border-radius: $border-radius-small;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $slate-50;
  border-radius: $border-radius-small;
}

::-webkit-scrollbar-thumb:horizontal {
  background: $slate-50;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $cyan;
}

// Special Selects
//------------------------------------------------------------------------

// Prefix Button

.prefix-button {
  text-transform: none;
  text-decoration: none;
  padding-left: 0.53rem;
  padding-right: 0.53rem;
  height: 2.42em;
  display: inline-block;
  border-radius: 0.375rem;
  background-color: $slate-10;
  position: relative;
  line-height: normal;
  font-size: $font-body-small;
  transition: $transition-2;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  /* hover */
  &:hover {
    background-color: $cyan-10;
  }

  /* click */
  &:active {
    background-color: $cyan-50;
  }

  .flag {
    margin-top: 15px;
  }

  .currencySymbol {
    margin-left: 1rem;
    margin-right: -1rem;
    width: 0;
  }
}

// Country Number Select

li.country {
  padding: 0.5rem;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
  transition: $transition-2;

  //  hover
  &:hover {
    border: 1px solid $slate-30;
    cursor: pointer;
  }

  // click
  &:active {
    border: 1px solid $black;
  }
}

.country .flag-box {
  display: inline-block;
  width: 20px;
}

.country .country-name {
  margin-left: 1rem;
}

.country .dial-code {
  margin-left: 0.5rem;
  opacity: 0.5;
}

.country-list {
  overflow-y: scroll;
  max-height: 165px;
  margin-bottom: -4px;
}

.iti-arrow {
  margin-left: 6px;
  margin-bottom: 4px;
  width: 6px;
  height: 6px;
  border: solid #00afef;
  display: inline-block;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Form Field
//------------------------------------------------------------------------
//------------------------------------------------------------------------

.form-field-basic {
  margin-bottom: 1rem;
  position: relative;

  // Label
  & label {
    font-size: $font-body-small;
    font-family: $lato-bold;
    color: $slate;
    display: flex;

    &.required:after {
      content: " *";
      color: $red;
    }
  }

  // Prefix
  & .prefix {
    position: absolute;
    pointer-events: none;
    display: inline;
    padding-left: $spacing-padding-tight;
    padding-top: 0.55rem;

    & ~ input {
      padding-left: 1.375rem;
    }
  }

  // Input
  & input,
  textarea {
    width: 100%;
    padding: $spacing-padding-tight;
    border-radius: $border-radius-small;
    border: 1px solid $slate-30;

    &:hover {
      border: 1px solid $black;
    }

    &:focus {
      border: 1px solid $cyan;
    }

    // Input Validation

    &.error-input {
      border: 1px solid $error;
    }

    &.warning-input {
      border: 1px solid $warning;
    }

    &.success-input {
      border: 1px solid $success;
    }
  }

  & .radioButton-input {
    width: auto;
    padding: 0;
  }

  // Prefix
  & .suffix {
    position: absolute;
    pointer-events: none;
    display: inline;
    padding-top: 0.4rem;
    right: 0.8rem;
    font-size: $font-heading-small;

    & ~ input {
      padding-right: 2rem;
    }
  }
}

.clickable {
  cursor: pointer;
  pointer-events: auto !important;
}

.iti-flag {
  background-image: url("/images/select/flags/flags.png") !important;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {
    background-image: url("/images/select/flags/flags@2x.png") !important;
  }
}

//  User Select
//  -----------------------------------------

.no-user-select {
  user-select: none;
}
