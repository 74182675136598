@import "../foundation/global-settings.scss";

// 1. Modal container
//------------------------------------------------------------
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $white;
    border-radius: $border-radius-medium;
    outline: 0;
}

// 2. Modal size
//------------------------------------------------------------

.modal-xl {
    max-width: 1400px;
}

.modal-lg {
    max-width: 800px;
}

.modal-md {
    max-width: 500px;
}

.modal-sm {
    max-width: 300px;
}

// 3. Header  +  Alerts Icon
//------------------------------------------------------------

.modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: $spacing-padding-medium;
    padding-bottom: 0px;
    border-bottom: none;
}

// 3.1 expanded-modal

.modal-header.expanded {
    padding: 1.75rem 1.75rem 1rem 1.75rem;
}

// 3.2 Alert modal Icon

.alert-Icon {
    font-size: $font-heading-larger;
    margin-right: $spacing-margin-close;
}

// 3.3 heading for alerts

.alerts-heading {
    margin-bottom: 0px;
}

// 4. Modal Body
//------------------------------------------------------------

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0 $spacing-padding-medium;
}

// 4.1 expanded-modal

.modal-body.expanded {
    padding: $spacing-padding-medium $spacing-padding-roomy;
}

// 5. Modal Footer
//------------------------------------------------------------

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: $spacing-padding-medium;
    border-top: 1px solid transparent;
}

// 5.1 expanded-modal

.modal-footer.expanded {
    padding: 1rem 1.75rem 1.75rem 1.75rem;
}

// 6. Headings
//-----------------------------------------

.modal-heading {
    font-size: $font-heading-medium;
    font-family: $lato-bold;
}

// 7. Modal backdrop
// ----------------------------------------

.modal-backdrop.show {
    opacity: 0.6;
}

.modal-backdrop {
    background-color: $black;
}
