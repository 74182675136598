@import "../foundation/global-settings.scss";

// Animations
// ----------

@-webkit-keyframes megaNavItemsAnimateIn {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-4px) translatex(-10px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes megaNavItemsAnimateIn {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-4px) translatex(-10px);
  }

  100% {
    opacity: 1;
  }
}

.displayMegaNav {
  -webkit-animation: slide-rotate-hor-top 0.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-rotate-hor-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-rotate-hor-top {
  0% {
    transform: translateY(0) rotateX(0deg);
  }
  100% {
    transform: translateY(-150px) rotateX(-90deg);
  }
}
@keyframes slide-rotate-hor-top {
  0% {
    transform: translateY(0) rotateX(0deg);
  }
  100% {
    transform: translateY(-150px) rotateX(-90deg);
  }
}

// 1. Slim Nav
//------------------------------------------------------------

.devider-vertical {
  height: 1rem;
  width: 1px;
  background-color: $slate-50;
  display: inline-flex;
}

.dropdown-menu.country-select {
  position: absolute;
  margin-top: 0px;
  right: 0;
  top: 0;
  z-index: 9999;

  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
}

// 2. Main Nav
// ------------------------------------------------------------

.navbar.main {
  background-color: $white;
  box-shadow: $shadow-noticeable-raise;
  padding: $spacing-padding-medium;
  border: 0.5px solid $slate-30;
  border-left: none;
  border-right: none;
}

.logo-img {
  height: 2rem;
  width: 5.5rem;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  &:focus {
    outline: none;
    outline-color: none;
    outline-style: none;
    outline-width: 0;
  }
}

.navbar .nav-center-mini {
  margin-top: -$spacing-padding-medium;
  margin-bottom: -$spacing-padding-medium;
  min-height: 32px;
  cursor: pointer;
}

.nav-link-bar-active {
  border-bottom: 2px solid $cyan !important;
}

p.nav-link-bar-active {
  display: inline !important;
  padding-bottom: 1px;
}

.nav-link-active {
  color: $cyan !important;
  border-bottom: 1px solid $cyan !important;
}

.nav-child-active > a {
  color: $cyan !important;
  border-bottom: 1px solid $cyan !important;
}

.submenu-heading {
  cursor: pointer;
  line-height: 18px;
}

.research-hub-subItem{
  cursor: pointer;
  
  &:hover {
    box-shadow: 0 1px 0 0 $slate-30;
  }
}

.menu-open {
  padding-bottom: 57px;
}

.mobile-nav-link-active {
  color: $black !important;
  background-color: $white !important;
  padding-left: 1.5rem !important;

  &::after {
    content: "";
    position: absolute;
    margin-left: -0.6rem;
    width: 2px;
    height: 24px;
    background-color: $cyan;
  }
}

.mobile-nav-child-active > a {
  color: $cyan !important;
  border-bottom: 1px solid $cyan !important;
}

// 3. Mega Nav
// ------------------------------------------------------------

.menu-item {
  display: inline-flex;
}

.dropdown-menu {
  &.mega-nav {
    border: 0.5px solid $slate-30;
    border-top: none !important;
    transform: translateY(-3%);
    opacity: 0;
    visibility: hidden;
    width: 100% !important;
    border-radius: 0 !important; 
    padding: $spacing-padding-medium !important;
    margin: 0 auto !important;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &.mega-nav.mobile-nav {
    height: 80vh;
    padding-bottom: 1.25rem;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &.mega-nav.mobile-nav .mobile-nav-items {
    margin: -0.75rem;
    padding: 0.75rem;
    overflow-y: scroll;
  }

  &.mobile-nav {
    margin-top: 64px !important;
    transform: translateY(50%) rotateX(-90deg);
    top: 15px !important;
  }

  &.secondary-nav {
    transform: translateX(40%) rotateY(90deg);
    opacity: 0;
    top: 0;
    visibility: hidden;
    width: 100%;
    min-height: 99%;
    background-color: $white;
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
    padding: $spacing-padding-medium;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &.nav-search {
    margin-top: 64px;
    max-height: 80vh;
    overflow: hidden;
  }

  &.show {
    transform: translateY(0%) translateX(0%) rotateY(0deg);
    opacity: 1;
    visibility: visible;
    top: 0;
  }
}

.menu-item > .mega-nav {
  display: block;
  visibility: visible;
  transform: translateY(0);
  box-shadow: 0px 4px 1rem -0.5rem rgba(0, 0, 0, 0.15);
  opacity: 1;
  a {
    -webkit-animation: megaNavItemsAnimateIn 300ms
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: megaNavItemsAnimateIn 300ms cubic-bezier(0.215, 0.61, 0.355, 1)
      both;

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        -webkit-animation-delay: 80ms * $i;
        animation-delay: 80ms * $i;
      }
    }
  }
}

.dropdown-menu:hover {
  &.mega-nav {
    display: block;
    visibility: visible;
  }
}

.dropdown-menu:hover + .nav-center-mini {
  color: $black;
  border-bottom: 1px solid $cyan;
}

.hamburger-button-container {
  margin-top: -1rem;
  margin-bottom: -1rem;
  padding: 1rem;
  padding-right: 0;
}

.devider-horizontal {
  height: 1px;
  width: 100%;
  margin-bottom: 1rem;
  background-color: $slate-50;
}

.align-items-start {
  right: 77px;
}
// Search UI
// -----------------------------------

.search-container {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: visible;
}

.search-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.search-item {
  -webkit-animation: animationFade 500ms $fluidcurve both;
  animation: animationFade 500ms $fluidcurve both;
  text-decoration: none;
  padding-left: $spacing-padding-small;
  padding-right: $spacing-padding-small;
  margin-left: -$spacing-padding-small;
  margin-right: -$spacing-padding-small;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
  transition: $transition-2;
  cursor: pointer;

  & a {
    text-decoration: none;
  }
  //  hover
  &:hover {
    border: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black;
    border: 1px solid $black;
  }
}

@media only screen and (min-width: 1350px) {
  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-none {
    display: none;
  }
}

//---------------

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  left: 0px;
  margin: 0 auto;
  box-shadow: $shadow-noticeable-raise;
}
