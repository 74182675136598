@import "../foundation/global-settings.scss";

.breadcrumb {
  list-style: none;
  background-color: transparent;
  display: inline;

  .icon {
    padding: 12px;
    color: $slate;
    font-size: $font-body-xsmall;
    font-weight: $font-weight-light;
    display: inline;
  }
}

.breadcrumb li {
  display: inline;
  font-size: 12px;
}

.breadcrumb li a {
  color: $slate;
  text-decoration: none;
}

.breadcrumb li a:hover {
  text-decoration: none;
  color: $cyan;
  border-bottom: 1px solid $cyan;
  padding-bottom: 0.25rem;
}

.breadcrumb-active {
  font-family: $lato-bold;
  font-weight: bold;
  color: $cyan;
}

.vertical-separator {
  border-right: 1px solid #dee2e6!important;
  height: 100%;
}

.hide-items {
  visibility: hidden !important;
  height: 0px !important;
}

.show-items {
  visibility: visible;
}

a.nav-left-mini {
  transition: all 0s !important;
  vertical-align: baseline !important;
}

//Vertical-separator heights
.height-96 { /*careers*/
  height: 96px;
}

.height-192 { /*save and invest*/
  height: 192px;
}

.height-160 { /*about us*/
  height: 160px;
}

.height-60 { /*contact us*/
  height: 60px;
}