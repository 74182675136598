@import "../foundation/global-settings.scss";

.avatar {
  display: flex;
}

.medium-avatar {
  vertical-align: middle;
  border-radius: $border-radius-small;
  width: 44px;
  height: 44px;
}

.text-medium-avatar {
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #9e916f;
  color: white;
  width: 44px;
  height: 44px;
  font-family: $font-family-1;
  border-radius: $border-radius-small;
  margin-right: 50px;
}

.text-large-avatar {
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #9e916f;
  color: white;
  width: 64px;
  height: 64px;
  border-radius: $border-radius-small;
}

.image-medium-avatar {
  width: 44px;
  height: 44px;
  border-radius: $border-radius-small;
  object-fit: cover;
  object-position: top;
}

.image-large-avatar {
  width: 64px;
  height: 64px;
  border-radius: $border-radius-small;
  object-fit: cover;
}

.image-large {
  width: 230px;
  height: 230px;
  border-radius: $border-radius-small;
  object-fit: cover;
}
