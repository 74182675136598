@import "../foundation/global-settings.scss";

// 1. all-multi-managed page
//----------------------------------------------------------------------------------------

.all-multi-managed-card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-extreme-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  .card-body {
    padding: 2rem 1.5rem;
  }
  &:hover {
    box-shadow: $shadow-significant-raise;
    transition: $transition-2;
  }
  @media screen and (max-width: 599px) {
    margin-top: 20px !important;
  }
}

.multi-managed-cards {
  margin-top: -4rem;
  @media screen and (max-width: 360px) {
    margin-top: -3rem;
  }
}

// 2. about us page - psg
//----------------------------------------------------------------------------------------

.aboutUsCard {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-extreme-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem;

  @media screen and (max-width: 599px) {
    padding: 2.5rem 1.75rem;
  }
  &.about-1 {
    padding: 2rem;
    @media screen and (max-width: 599px) {
      padding: 2.5rem 1.75rem;
    }
  }

  &.about-2 {
    padding: 2rem;
    @media screen and (max-width: 599px) {
      padding: 2.5rem 1.75rem;
    }
  }
}

.aboutUs-psg-row {
  margin-top: -17rem;
  @media screen and (max-width: 1025px) {
    margin-top: -10rem;
  }
  @media screen and (max-width: 599px) {
    margin-top: -1rem;
  }
}

//---------------------------------
// team flip card
//---------------------------------

.team-flipCard-bg-img {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  border-radius: $border-radius-medium;
  box-shadow: $shadow-noticeable-raise;
  height: 18rem;
  cursor: pointer;
  & :hover {
    // box-shadow: 5px 5px 5px 5px #000000;
    box-shadow: 0 3px 2rem -0.5rem #000000;
    transition: $transition-2;
    & h5 {
      color: $cyan;
      transition: $transition-2;
    }
  }
}

.team-flipCard {
  border-radius: $border-radius-medium;
  // box-shadow: $shadow-noticeable-raise;
  transition: $transition-2;
  height: 18rem;
}

.team-flipCard p {
  line-height: 1.2em;
  color: $white;
}

.front {
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(30, 30, 30, 0.1) 55%,
    rgba(30, 30, 30, 0.2) 60%,
    rgba(30, 30, 30, 1) 100%
  );
}

.card-black-layer {
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(30, 30, 30, 0.1) 55%,
    rgba(30, 30, 30, 0.2) 60%,
    rgba(30, 30, 30, 1) 100%
  );
  border-radius: $border-radius-medium;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 1rem;
}

.back {
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0.6) 0%,
    rgba(30, 30, 30, 0.8) 50%,
    rgba(30, 30, 30, 1) 100%
  );
}

.front,
.back {
  border-radius: $border-radius-medium;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 1rem;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.back {
  transform: rotateX(180deg);
}

.team-bg-img:hover > .team-flipCard {
  transform: rotateX(180deg);
  transition-duration: 0.5s;
}

.team-flipCard:hover {
  transform: rotateX(180deg);
  transition-duration: 0.5s;
}

.team-flipCard {
  transition-duration: 0.5s;
}
.front {
  position: absolute;
}

.team-flipCard {
  transform-style: preserve-3d;
}

//4. contact us
//---------------------------------------------------------------------------------------

.contactUs--pt {
  margin-top: -5rem;
}

.contact-Us-Card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-extreme-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 2.5rem 1rem 2rem 1rem;
  text-align: center;
  @media screen and (max-width: 599px) {
    padding: 2.5rem 2rem;
  }
}

.contactUs-details {
  margin-top: 8rem !important;
}

.contactUs-details-img-div {
  margin-top: -7rem;
}

//-----------------------------------------------------------
//save and invest page
//-----------------------------------------------------------

.saveAndInvestCard-Mt {
  margin-top: -5rem;
}

.saveAndInvest-Card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-slight-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

//saving card
//------------------------------------------------------------
.savings-header {
  margin-top: -5rem;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
}

.saving-card-bg-img {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  border-radius: $border-radius-medium;
  height: 20rem;
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

.saving-card-bg-img-height {
  height: 22rem;
  margin-top: -16px;
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

.saving--card {
  border-radius: 1rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-size: cover;
  padding: 1rem;
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(30, 30, 30, 0.1) 55%,
    rgba(30, 30, 30, 0.2) 60%,
    #1e1e1e 100%
  );
}

.investement--card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-slight-raise;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem;
  height: 176px;

  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

.investement-card {
  height: 77%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  position: absolute;
}

.retirement-card-img {
  width: 100%;
  border-radius: $border-radius-medium;
  height: 358px;
}

.retirement-header {
  margin-top: -5rem;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
}

//------------------------------------------------------------
//savings page
//------------------------------------------------------------

.savings-page-cards {
  margin-top: -5rem;
}

.blur-card {
  &--savings {
    box-shadow: $shadow-significant-raise;
    background-color: $white-80;
    backdrop-filter: blur(20px);
    border-radius: $border-radius-medium;
    transition: $transition-2;
    border: none;
    backdrop-filter: blur(20px);
    padding: 2.5rem 2rem;
    @media screen and (max-width: 1025px) {
      padding: 2rem 1.75rem;
    }
  }
}

//retirement planning page
//-------------------------------------------------------------
.blur-card {
  &--retire {
    box-shadow: $shadow-significant-raise;
    background-color: $white-80;
    backdrop-filter: blur(20px);
    border-radius: $border-radius-medium;
    transition: $transition-2;
    border: none;
    backdrop-filter: blur(20px);
    padding: 2.5rem 2rem;
    @media screen and (max-width: 1025px) {
      padding: 2rem 1.75rem;
    }
  }
}
.retirement-planning-cards {
  margin-top: -5rem;
}

//all psg funds page
//------------------------------------------------
.all-psg-funds-card-mt {
  margin-top: -8rem;
}

.all-psg-funds-card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-slight-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    & > div .large-heading {
      color: $cyan;
      transition: $transition-2;
    }
  }
  &:active {
    box-shadow: $shadow-significant-raise;
  }
}

//psg-multi-managed-funds page
//-------------------------------------------------------------

.psg-multi-managed-funds-MT {
  margin-top: -5rem;
}
.psg-multi-managed-funds-card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-extreme-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: $white;
  &:hover {
    cursor: pointer;
    & > div .large-heading {
      color: $cyan;
      transition: $transition-2;
    }
  }
}

// psg-single-managed-funds page
//------------------------------------------------------------

.psg-single-managed-funds-card {
  box-shadow: 0px 1px 0.5rem 0rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  border-radius: $border-radius-medium;
  transition: $transition-2;
  border: none;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    & > div .large-heading {
      color: $cyan;
      transition: $transition-2;
    }
  }

  &:active {
    box-shadow: $shadow-significant-raise;
  }
}

//business-investment-services page

.business-investment-services-card-MT {
  margin-top: -6rem;
}

.business-investment-services-card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-slight-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    & > div .large-heading {
      color: $cyan;
      transition: $transition-2;
    }
  }
  &:active {
    box-shadow: $shadow-significant-raise;
  }
}

.retirement-fund-card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-slight-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;

  height: 100%;
  background: $white;
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    & > h4 {
      color: $cyan;
      transition: $transition-2;
    }
  }

  &:active {
    box-shadow: $shadow-significant-raise;
  }
}

// adviser-profile page
//-------------------------------------------------------------

.adviser-profile-card-mt {
  margin-top: -16rem;
}

.adviser-profile-card-bg-img {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  border-radius: $border-radius-medium;
  height: 20rem;
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

.adviser-profile-card-bg-img-height {
  height: 22rem;
  margin-top: -16px;
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

.my-qualifications {
  right: 176px;
  @media screen and (max-width: 767px) {
    right: 0;
  }
}

.hide-heading-mobile {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.hide-heading-desktop {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.adviser-contact {
  margin-top: -2rem;
  @media screen and (min-width: 992px) {
    margin-top: -7rem;
  }
}

//-------------------------------------------------------------
// flat bordered cards with hover shadows
//-------------------------------------------------------------

.card-flat {
  border: 1px solid $slate-10;
  background-color: $white;
  border-radius: 1rem;
  transition: $transition-2;
  text-decoration: none;
  padding: 2.5rem 2rem;
  @media screen and (max-width: 1025px) {
    padding: 2rem 1.75rem;
  }
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    & > .medium-heading {
      color: $cyan;
      transition: $transition-2;
    }
    & .small-heading.landingPage {
      color: $cyan;
      transition: $transition-2;
    }
  }

  // extra styles for specific uses
  &.insure-product {
    @media screen and (min-width: 1279px) {
      padding: 2.75rem;
    }
  }
}

//------------------------------------------------------------
// compare products cards
//------------------------------------------------------------

.compare-card {
  background-color: $white;
  border-radius: 0.5rem;
  padding: 2rem 1.75rem;
  height: 100%;
  border: $border-sm solid $slate-10;
  > .inner-cardSection {
    background-color: $slate-10;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
  @media screen and (max-width: 1025px) {
    padding: 2rem 1.75rem;
  }
}

.inset-shadow {
  box-shadow: inset $shadow-slight-raise;
}

// stockbroking page
//---------------------------------------------------

.blur-card {
  box-shadow: $shadow-significant-raise;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  border-radius: $border-radius-medium;
  transition: $transition-2;
  border: none;
  backdrop-filter: blur(20px);
  padding: 2.5rem 2rem;
  @media screen and (max-width: 1025px) {
    padding: 2rem 1.75rem;
  }
}

.translate-y-stock {
  transform: translateY(-15%);
  margin-bottom: -180px;
  @media screen and (max-width: 1025px) {
    margin-bottom: -80px;
    transform: translateY(-15%);
  }
  @media screen and (max-width: 1025px) {
    margin-bottom: -64px;
    transform: translateY(-8%);
  }
}

//---------------------------------------------------------
// find and adviser
//---------------------------------------------------------

.findAdviser-searchCard {
  margin-bottom: -90px;
}

.profile-card {
  box-shadow: $shadow-noticeable-raise;
  background-color: $white;
  border-radius: $border-radius-medium;
  transition: $transition-2;
  border: none;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: $shadow-significant-raise;
  }

  @media screen and (max-width: 1025px) {
  }
}

.adviser-b-w {
  filter: grayscale(1);
}

.btn-100-fA > button-icon > button.button {
  width: 48%;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem !important;
  }
}

// adviser profile
//----------------

.specialise-card:not(:last-child) {
  margin-right: 0.5rem;
}
.advisor-profile {
  background-color: rgba($cyan, 0.05);
}

.advisor-profile-card-bg-img {
  width: 15rem;
  height: 15rem;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media screen and (max-width: 1280px) {
    height: 300px !important;
    width: 300px !important;
  }

  @media screen and (max-width: 992px) {
    height: 240px !important;
    width: 240px !important;
  }

  @media screen and (max-width: 768px) {
    height: 220px !important;
    width: 220px !important;
  }
  @media screen and (max-width: 567px) {
    height: 180px !important;
    width: 180px !important;
  }
}

//Insurence page

.insurence-card-mt {
  margin-top: -8rem;
  @media screen and (max-width: 767px) {
    margin-top: -1rem;
  }
}

//insurance

.insurence-card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-slight-raise;
  transition: $transition-2;
  border: none;
  background-color: $white-80;
  backdrop-filter: blur(20px);
  padding: 3rem 2rem;
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    & > div .large-heading {
      color: $cyan;
      transition: $transition-2;
    }
  }
  &:active {
    box-shadow: $shadow-significant-raise;
  }
}

.week-ahead-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.markets-manual-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.macroeconomics-insights-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2rem;
  backdrop-filter: blur(20px);
}

.fund-research-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.equity-research-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.research-insights-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.in-the-media-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.house-view-equity-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.fund-of-funds-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

.research-insights-hero-card-content > p {
  color:#000000 !important;
  max-width: none !important;
}

//---------------------------------------------------------
// find an adviser card that overlaps the hero
//---------------------------------------------------------
.find-adviser-hero-card {
  background-color: $white-80;
  box-shadow: $shadow-extreme-raise;
  border-radius: $border-radius-medium;
  padding: 2.5rem;
  backdrop-filter: blur(20px);
}

//psg wealth
.investment-philosophy-card-w {
  min-width: 210px;
}

.OurFund-FindAdviser-mt {
  margin-top: -7rem;
  @media screen and (max-width: 767px) {
    margin-top: -3rem;
  }
}

.hide-cards-on-desktop {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

//psg asset management page
.process-and-funds-cards-mt {
  margin-top: -10rem;
}

// finacial advisor landing page

.finacial-advisor {
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-significant-raise;
    transition: $transition-2;
    & > div .large-heading {
      color: $cyan;
      transition: $transition-2;
    }
  }
}

.falcon--card {
  border-radius: 1rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-size: cover;
  padding: 1rem;
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(30, 30, 30, 0.1) 55%,
    rgba(30, 30, 30, 0.2) 60%,
    #1e1e1e 100%
  );
}

.falcon-card-bg-img {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  border-radius: $border-radius-medium;
  height: 14rem;
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
}

.falcon--card {
  border-radius: 1rem;
  height: 100%;
  flex-flow: column;
  justify-content: flex-end;
  background-size: cover;
  padding: 1rem;
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(30, 30, 30, 0.1) 55%,
    rgba(30, 30, 30, 0.2) 60%,
    #1e1e1e 100%
  );
}

.week--card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-noticeable-raise;
  transition: $transition-2;
  border: none;

  .card-img-top {
      border-radius: $border-radius-medium $border-radius-medium 0 0;
      object-fit: cover;
      height: 10rem;
      @media screen and (max-width: 767px) {
          max-height: 10rem;
      }
  }
  .card-body {
      padding: 0.75rem 1rem 2rem 1rem;
      h5.small-heading {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.1;
      }
      p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
      }
  }

  &:hover {
      box-shadow: $shadow-significant-raise;
      transition: $transition-2;
  }
}

.card-deck .card.week--card {
  flex: none !important;
  @media screen and (max-width: 992px) {
      margin-right: 15px;
      margin-bottom: 30px;
      margin-left: 15px;
  }
}

@media only screen and (min-width: 992px) 
  {
    .week-ahead-cards > .card {
      margin-right: 0px !important;
      max-width: 12.666667%;
    }
  }

.week-ahead-cards > .card > .card-body {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;;
}

.week--card.header-row {
  box-shadow: $shadow-extreme-raise;

  &:hover {
      box-shadow: $shadow-extreme-raise;
  }
}

.content-separator {
  border-right: 1px solid #dee2e6!important;
  height: 100%;
}

.research-hub--card {
  border-radius: $border-radius-medium;
  box-shadow: $shadow-noticeable-raise;
  transition: $transition-2;
  border: none;

  .card-img-top {
      border-radius: $border-radius-medium $border-radius-medium 0 0;
      object-fit: cover;
      height: 10rem;
      @media screen and (max-width: 767px) {
          max-height: 10rem;
      }
  }
  .card-body {
      padding: 0.75rem 1rem 2rem 1rem;
      h5.small-heading {
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.1;
      }
      p {
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
      }
  }

  &:hover {
      box-shadow: $shadow-significant-raise;
      transition: $transition-2;
  }
}

.card-deck .card.research-hub--card {
  @media screen and (max-width: 992px) {
      flex: 1 1 40% !important;
      margin-right: 15px;
      margin-bottom: 30px;
      margin-left: 15px;
  }
}
