@import "../foundation/global-settings.scss";

//----------

.owl-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        justify-content: space-around;
    }
}

.owl-theme .owl-nav [class*="owl-"] {
    color: #1e1e1e !important;
    font-size: 18px !important;
    margin: 5px;
    padding: 8px 10px 8px 10px !important;
    background: transparent !important;
    border: 1px solid $slate-30 !important;
    cursor: pointer;
    border-radius: $border-radius-small !important;
    height: 44px;
    min-width: 44px;

    //  hover
    &:hover {
        border: 1px solid $black !important;
    }

    // click
    &:active {
        border: 1px solid $cyan !important;
    }
}

.owl-nav {
    transform: translate(98px, -18px);
    @media screen and (max-width: 767px) {
        transform: none;
    }
}

.owlNav-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

.owlNav-t {
    margin-bottom: 0.125rem;
}

.slides-scale {
    transform: scale(0.95);
    @media screen and (max-width: 1441px) {
        transform: scale(0.9);
    }
    @media screen and (max-width: 1367px) {
        transform: scale(0.9);
    }

    @media screen and (max-width: 1281px) {
        transform: scale(0.9);
    }

    @media screen and (max-width: 767px) {
        transform: scale(1);
    }
}

//-----------------------------------------------------------------------
// stockbroking tabs
//-----------------------------------------------------------------------

.stockbroking-options {
    max-height: auto;
}

.img-cover-stockb {
    width: 100%;
    height: 253px;
    object-fit: cover;
    border-radius: 0.5rem;

    @media screen and (max-width: 767px) {
        border-radius: 0.5rem 0.5rem 0 0;
    }
}

.tab-nowrap-space > .tab {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
}

.blurEdge > .tab::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 44px;
    background: linear-gradient(
        to right,
        rgba(127, 127, 127, 0.01) 0%,
        rgba(242, 242, 242, 0.6) 50%,
        rgba(242, 242, 242, 1) 100%
    );
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabScroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabScroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.info-stockb {
    border-radius: 0.5rem;
    max-height: 253px;
    @media screen and (max-width: 767px) {
        border-radius: 0 0 0.5rem 0.5rem;
        max-height: 100%;
    }
}

//for-yourself
//-----------------------------------------------------
#for-yourself-carousel .owl-nav {
    transform: translate(-47px, -18px);
    @media screen and (max-width: 767px) {
        transform: none;
    }
}

//for-yourthings
//-----------------------------------------------------
#for-yourthings-carousel .owl-nav {
    transform: translate(-47px, -18px);
    @media screen and (max-width: 767px) {
        transform: none;
    }
}

//Advice steps
//-----------------------------------------------------

.advice-steps-process {
    border-radius: 0.5rem;
    max-height: 300px;
}
