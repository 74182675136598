@import "../foundation/global-settings.scss";

// Animations
// ----------------------------------------------

// animation check in

@-webkit-keyframes check-in {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
@keyframes check-in {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// removes browser focus
//------------------------------------------------------------------------
button:focus {
  outline: none;
}

// Button Text
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Default
//------------------------------------------------------------------------
.button,
.button:link,
.button:visited {
  text-transform: none;
  text-decoration: none;
  padding: $spacing-padding-small;
  display: inline-flex;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
  position: relative;
  line-height: 1em;
  font-family: $lato-bold;
  font-weight: $font-weight-bold;
  font-size: $font-body-small;
  font-size: $font-body-small;
  transition: $transition-2;
  vertical-align: middle;
  margin-left: 0;
  min-height: 44px;
  max-height: 44px;
  min-width: 44px;
  cursor: pointer;

  // disabled
  &:disabled {
    cursor: not-allowed;
  }
}

.button.small {
  padding: $spacing-padding-tight;
  font-family: $lato-bold;
  font-weight: $font-weight-bold;
  font-size: $font-body-xsmall;
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
}

// Simple
//------------------------------------------------------------------------
.button.simple,
.button.simple:link,
.button.simple:visited {
  background: transparent;
  //  hover
  &:hover {
    border: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black;
    border: 1px solid $black;
  }

  // disabled
  &:disabled {
    border: 1px solid transparent;
    opacity: 0.6;
  }
}

// Solid
//------------------------------------------------------------------------

.button.solid,
.button.solid:link,
.button.solid:visited {
  background-color: $cyan;
  color: $white;
  box-shadow: $shadow-colour-cyan;

  //  hover
  &:hover {
    background-color: $cyan-80;
    box-shadow: $shadow-colour-cyan-hover;
  }

  // click
  &:active,
  .active {
    background-color: $cyan;
  }
  // click

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
    box-shadow: none;
  }
  &.active.outline,
  &.active.outline:hover {
    color: $cyan;
    border: 2px solid $cyan;
    box-shadow: inset 0px 6px 14px #0000004e;
    background-color: inherit;
  }
}

// Solid Error button -- styles overide the default to make this button red
.button.solid.error,
.button.solid.error:link,
.button.solid.error:visited {
  background-color: $error;

  //  hover
  &:hover {
    background-color: $red-80;
    box-shadow: none;
  }

  // click
  &:active {
    background-color: $error;
  }

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
  }
}

// Solid warning button -- styles overide the default to make this button yellow
.button.solid.warning,
.button.solid.warning:link,
.button.solid.warning:visited {
  background-color: $warning;
  color: $black;

  //  hover
  &:hover {
    background-color: $yellow-80;
    box-shadow: none;
  }

  // click
  &:active {
    background-color: $warning;
  }

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
  }
}

// green  button -- styles overide the default to make this button green
.button.green,
.button.green:link,
.button.green:visited {
  background-color: $green-20;
  color: $white;

  //  hover
  &:hover {
    background-color: $green-10;
    box-shadow: none;
  }

  // click
  &:active {
    background-color: $cyan;
  }

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
  }
}

// blue  button -- styles overide the default to make this button green
.button.blue,
.button.blue:link,
.button.blue:visited {
  background-color: $blue-20;
  color: $white;

  //  hover
  &:hover {
    background-color: $blue-10;
    box-shadow: none;
  }

  // click
  &:active {
    background-color: $cyan;
  }

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
  }
}

// dark blue button
.button.solid.dark-blue,
.button.solid.dark-blue:link,
.button.solid.dark-blue:visited {
  background-color: $dark-blue;
  color: $white;

  //  hover
  &:hover {
    background-color: $dark-blue-10;
    box-shadow: none;
  }

  // click
  &:active {
    border: 3px solid $black;
  }

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
  }
}

// Solid warning button -- styles overide the default to make this button yellow
.button.solid.altWhite,
.button.solid.altWhite:link,
.button.solid.altWhite:visited {
  background-color: $white;
  color: $black;

  //  hover
  &:hover {
    background-color: $white-80;
    box-shadow: none;
  }

  // click
  &:active {
    background-color: $cyan;
  }

  // disabled
  &:disabled {
    background: $slate;
    opacity: 0.6;
  }
}

// Shaded
//------------------------------------------------------------------------

.button.shaded,
.button.shaded:link,
.button.shaded:visited {
  background-color: $slate-10;

  //hover
  &:hover {
    background-color: $slate-30;
  }

  // click
  &:active {
    color: $black;
    background-color: $slate-10;
  }

  // disabled
  &:disabled {
    opacity: 0.6;
    background-color: $slate-10;
  }
}

// Outline
//------------------------------------------------------------------------
.button.outline,
.button.outline:link,
.button.outline:visited {
  background: transparent;
  border: 1px solid $slate-30;

  //  hover
  &:hover {
    border: 1px solid $black;
  }

  // click
  &:active {
    color: $black;
    border: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    border: 1px solid $black-10;
    opacity: 0.6;
  }
}

// outline - inversed  -- used on slider

.button.outline.inverse,
.button.outline.inverse:link,
.button.outline.inverse:visited {
  background: transparent;
  border: 1px solid $white-30;
  color: $white;

  //  hover
  &:hover {
    border: 1px solid $white;
  }

  // click
  &:active {
    border: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    border: 1px solid $white-10;
    opacity: 0.6;
  }
}

// TextLink
//------------------------------------------------------------------------
a,
.textlink,
.button.textlink,
.button.textlink:link,
.button.textlink:visited {
  background: none;
  color: $cyan;

  //hover
  &:hover {
    color: $cyan;
    text-decoration: underline;
  }

  // click
  &:active {
    color: $black-80;
    text-decoration: underline;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    text-decoration: none;
  }
}

// NavLinkLeft nav-left
//------------------------------------------------------------------------
.button.nav-left,
.button.nav-left:link,
.button.nav-left:visited {
  text-align: left;
  background: transparent;
  color: $black-80;
  border-radius: 0;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;
  font-weight: $font-weight-normal;
  justify-content: start;

  //hover
  &:hover {
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black-80;
    border-bottom: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    text-decoration: none;
    border: 1px solid transparent;
  }
}

// NavLinkCenter
//------------------------------------------------------------------------
.button.nav-center,
.button.nav-center:link,
.button.nav-center:visited {
  text-align: center;
  background: transparent;
  border-radius: 0;
  color: $black-80;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;

  //hover
  &:hover {
    color: $black;
    border-bottom: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black-80;
    border-bottom: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    text-decoration: none;
    border: 1px solid transparent;
  }
}

// NavLinkLeftMini
//------------------------------------------------------------------------
.button.nav-left-mini,
.button.nav-left-mini:link,
.button.nav-left-mini:visited {
  text-align: left;
  background: transparent;
  color: $black-80;
  border-radius: 0;
  font-size: $font-body-xsmall;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;
  justify-content: start;

  //hover
  &:hover {
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black-80;
    border-bottom: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    border: 1px solid transparent;
  }
}

// NavLinkLeftMini - Inverse
//------------------------------------------------------------------------

.button.nav-left-mini.inverse,
.button.nav-left-mini.inverse:link,
.button.nav-left-mini.inverse:visited {
  color: $white;

  //hover
  &:hover {
    border-bottom: 1px solid $white-30;
  }

  // click
  &:active {
    border-bottom: 1px solid $cyan;
    color: $cyan;
  }
}

// NavLinkCenterMini
//------------------------------------------------------------------------
.button.nav-center-mini,
.button.nav-center-mini:link,
.button.nav-center-mini:visited {
  text-align: center;
  background: transparent;
  color: $black;
  font-size: $font-body-small;
  border-radius: 0;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;

  //hover
  &:hover {
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid $cyan-80;
  }

  // click
  &:active {
    color: $cyan;
    border-bottom: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    border: 1px solid transparent;
  }
}

// Icon Text Buttons
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Simple Icon Button
//------------------------------------------------------------------------
.button.simple-icon-text,
.button.simple-icon-text:link,
.button.simple-icon-text:visited {
  background: transparent;
  color: $black;

  //  hover
  &:hover {
    border: 1px solid $slate-30;
  }

  // click
  &:active {
    border: 1px solid $black;
  }

  // disabled
  &:disabled {
    border: 1px solid transparent;
    opacity: 0.6;
    .icon {
      color: $slate-30;
    }
  }

  .icon {
    color: $cyan;
    margin-right: 12px;
  }
}

// Solid Icon Text Button
//------------------------------------------------------------------------
.button.solid-icon-text,
.button.solid-icon-text:link,
.button.solid-icon-text:visited {
  background-color: $cyan;
  color: $white;
  box-shadow: $shadow-colour-cyan;

  //  hover
  &:hover {
    background-color: $cyan-80;
    box-shadow: $shadow-colour-cyan-hover;
  }

  // click
  &:active {
    background-color: $cyan;
  }

  // disabled
  &:disabled {
    box-shadow: none;
    background: $slate;
    opacity: 0.6;
    .icon {
      color: white;
    }
  }

  .icon {
    color: white;
    margin-right: 12px;
  }
}

// Shaded Icon Text Buttons
//------------------------------------------------------------------------

.button.shaded-icon-text,
.button.shaded-icon-text:link,
.button.shaded-icon-text:visited {
  background-color: $slate-10;
  color: $black;

  // hover
  &:hover {
    background-color: $slate-30;
  }

  //click
  &:active {
    background-color: $slate-10;
  }

  // disabled
  &:disabled {
    opacity: 0.6;
    background-color: $slate-10;
    .icon {
      color: $slate-30;
    }
  }

  .icon {
    color: $cyan;
    margin-right: 12px;
  }
}

// Outline Icon Text Button
//------------------------------------------------------------------------
.button.outline-icon-text,
.button.outline-icon-text:link,
.button.outline-icon-text:visited {
  background: transparent;
  border: 1px solid $slate-30;

  //  hover
  &:hover {
    border: 1px solid $black;
  }

  // click
  &:active {
    border: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    border: 1px solid $black-10;
    opacity: 0.6;
    .icon {
      color: $slate-30;
    }
  }

  .icon {
    color: $cyan;
    margin-right: 12px;
  }
}

// NavLinkLeft nav-left
//------------------------------------------------------------------------
.button.nav-left-icon,
.button.nav-left-icon:link,
.button.nav-left-icon:visited {
  text-align: left;
  background: transparent;
  text-transform: none;
  color: $black-80;
  border-radius: 0;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;
  justify-content: left;

  //hover
  &:hover {
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid $slate-30;
  }

  // click
  &:active {
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    text-decoration: none;
    border: 1px solid transparent;
    .icon {
      color: $slate-30;
    }
  }

  .icon {
    color: $cyan;
    margin-right: 12px;
  }
}

// Icon Buttons
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Simple Icon Button
//------------------------------------------------------------------------
.button.simple-icon,
.button.simple-icon:link,
.button.simple-icon:visited {
  background: transparent;
  padding: $spacing-padding-tight;

  //  hover
  &:hover {
    border: 1px solid $slate-30;
  }

  // click
  &:active {
    border: 1px solid $black;
  }

  // disabled
  &:disabled {
    border: 1px solid transparent;
    opacity: 0.6;
    .icon {
      color: $slate-30;
    }
  }
}

// Solid Icon Button
//------------------------------------------------------------------------
.button.solid-icon,
.button.solid-icon:link,
.button.solid-icon:visited {
  background-color: $cyan;
  color: $white;
  padding: $spacing-padding-tight;
  box-shadow: $shadow-colour-cyan;

  //  hover
  &:hover {
    background-color: $cyan-80;
    box-shadow: $shadow-colour-cyan-hover;
  }

  // click
  &:active {
    background-color: $cyan;
  }

  // disabled
  &:disabled {
    box-shadow: none;
    background: $slate;
    opacity: 0.6;
    .icon {
      color: white;
    }
  }

  .icon {
    color: white;
  }
}

// Shaded Icon Buttons
//------------------------------------------------------------------------

.button.shaded-icon,
.button.shaded-icon:link,
.button.shaded-icon:visited {
  background-color: $slate-10;
  padding: $spacing-padding-tight;

  // hover
  &:hover {
    background-color: $slate-30;
  }

  //click
  &:active {
    background-color: $slate-10;
  }

  // disabled
  &:disabled {
    opacity: 0.6;
    background-color: $slate-10;
    .icon {
      color: $slate-30;
    }
  }

  .icon {
    color: $cyan;
  }
}

// Outline Icon Button
//------------------------------------------------------------------------
.button.outline-icon,
.button.outline-icon:link,
.button.outline-icon:visited {
  background: transparent;
  border: 1px solid $slate-30;
  padding: $spacing-padding-tight;

  //  hover
  &:hover {
    border: 1px solid $black;
  }

  // click
  &:active {
    border: 1px solid $cyan;
  }

  // disabled
  &:disabled {
    border: 1px solid $black-10;
    opacity: 0.6;
    .icon {
      color: $slate-30;
    }
  }
}

// Nav Large
//------------------------------------------------------------------------
.button.nav-large,
.button.nav-large:link,
.button.nav-large:visited {
  text-align: left;
  background: $white;
  color: $black-80;
  border-radius: $border-radius-medium;
  padding-top: $spacing-padding-tight;
  padding-bottom: $spacing-padding-tight;
  font-family: $lato-bold;
  justify-content: space-between;
  min-height: 56px;
  max-height: 56px;

  //hover
  &:hover {
    color: $black;
    text-decoration: none;

    .icon {
      color: $cyan;
    }
  }

  // click
  &:active {
    background-color: transparent;
    box-shadow: none;
    color: $black-80;
  }

  // disabled
  &:disabled {
    color: $slate-30;
    text-decoration: none;
    border: 1px solid transparent;
  }

  // nav element that expands
  &.expand {
    min-height: 56px;
    max-height: none;
  }

  &[data-toggle="collapse"].expand {
    background-color: $white;
    padding-bottom: 1.5rem;
  }

  &[data-toggle="collapse"].expand.collapsed {
    background-color: $white;
    padding-bottom: $spacing-padding-tight;
  }

  &[data-toggle="collapse"].expand .inner-button {
    color: $black;
  }

  &[data-toggle="collapse"].expand.collapsed .inner-button {
    color: $black;
  }

  &.inner-button {
    background-color: transparent;
    padding: 0px;
    box-shadow: none;
  }
}

//Fixed icon button spin on dropdown
.button.nav-large[data-toggle="collapse"].expand.collapsed
  .inner-button
  > .icon-rotate {
  display: inline-block;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: $transition-2;
}

.icon-rotate {
  transition: $transition-2;
}

// Image Button
//------------------------------------------------------------------------
//------------------------------------------------------------------------

.image-button-container {
  column-count: 1;
}

.image-button {
  cursor: pointer;
  border-radius: $border-radius-small;
  border: none;
  overflow: hidden;
  min-height: 3.25rem;
  transform: rotate(0);
  display: block;
  background-color: $slate-10;
  user-select: none;

  & .image {
    border: none;
    height: inherit;
    object-fit: cover;
    transform: scale(1);
    transition: $transition-3;
    position: relative;
  }

  &:hover .image {
    transform: scale(1.1);
  }

  &:active .image {
    transform: scale(1);
  }

  & .overlay {
    border-radius: $border-radius-small;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    background-color: $black-80;

    &.light {
      background-color: $white-80;
    }
  }

  & .caption {
    position: absolute;
    bottom: 0.8rem;
    left: 0;
    margin-left: 0.75rem;
    padding: 0.35rem;
    height: 1.625rem;
    border-left: 2px solid $cyan;
    transition: $transition-3;

    &.red {
      border-left: 2px solid $red;
    }

    &.green {
      border-left: 2px solid $green;
    }

    &.blue {
      border-left: 2px solid $blue;
    }

    &.yellow {
      border-left: 2px solid $yellow;
    }

    &.purple {
      border-left: 2px solid $purple;
    }
  }

  & .caption .caption-text {
    transition: $transition-3;
  }

  &:hover .caption-text {
    transform: translateX(8px);
  }

  &:active .caption-text {
    transform: translateX(12px);
  }
}

// Button Select
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Check
//------------------------------------------------------------------------

// Customize the label (the container)
.check-container {
  text-transform: none;
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: $spacing-padding-tight;
  padding-left: 40px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & p {
    float: left;
    margin-top: 0.05rem;
  }
}

label.check-container {
  margin-left: 0px;
}

// Hide the browser's default checkbox
.check-container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

// Custom checkbox
.check-checkmark {
  transition: all 0.2s;
  position: absolute;
  top: 11px;
  left: 11px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $slate-50;
}

.check-background {
  transition: all 0.2s;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 2.6rem;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
}

// Hover
.check-container:hover > .check-background {
  border: 1px solid $slate-30;
}

.check-container input:hover ~ .check-checkmark {
  border: 1px solid $black;
}

.check-container input:checked ~ .check-checkmark {
  background-color: $cyan;
  border: 1px solid $cyan;
}

.check-container input:checked ~ .check-background {
  border: 1px solid $cyan;
}

// Disbaled
.check-container input:disabled ~ .check-checkmark {
  border: 2px solid $slate;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .check-checkmark:after {
  display: block;
  -webkit-animation: check-in 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
  animation: check-in 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
}

/* Style the checkmark/indicator */
.check-container .check-checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
}

// Radio
//------------------------------------------------------------------------

// Customize the label (the container)
.radio-container {
  text-transform: none;
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: 8px;
  padding-left: 40px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & p {
    float: left;
    margin-top: 0.05rem;
  }
}

// Hide the browser's default checkbox
.radio-container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

// Custom checkbox
.radio-checkmark {
  transition: all 0.2s;
  position: absolute;
  top: 11px;
  left: 11px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid $slate-50;
}

.radio-background {
  transition: all 0.2s;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 2.6rem;
  border-radius: $border-radius-small;
  border: 1px solid transparent;
}

// Hover
.radio-container:hover > .radio-background {
  border: 1px solid $slate-30;
}

.radio-container input:hover ~ .radio-checkmark {
  border: 1px solid $black;
}

.radio-container input:checked ~ .radio-checkmark {
  background: transparent;
  border: 8px solid $cyan;
}

.radio-container input:checked ~ .radio-background {
  border: 1px solid $cyan;
}

// Disbaled
.radio-container input:disabled ~ .radio-checkmark {
  border: 2px solid $slate;
}

// Toggle Switch

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: $border-radius-small;
  border: 1px solid $slate-30;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: $black;
  border-radius: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transform: translateZ(10px);
}

input:hover + .toggle {
  border: 1px solid $black;
}

input:checked + .toggle {
  border: 1px solid $cyan;
}

input:checked + .toggle:before {
  background-color: $cyan;
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

// Button Select Password Verification
//------------------------------------------------------------------------
//------------------------------------------------------------------------

// Check  Password Verification
//------------------------------------------------------------------------

// Customize the label (the container)
.check-password-container {
  text-transform: none;
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: 8px;
  padding-left: 40px;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
  border-radius: $border-radius-small;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Hide the browser's default checkbox
.check-password-container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

// Custom checkbox
.check-password-checkmark {
  transition: all 0.2s;
  position: absolute;
  top: 11px;
  left: 11px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $slate-50;
  font-weight: bold;
}

.check-password-container input:hover ~ .check-password-checkmark {
  border: 1px solid $slate-50;
  font-weight: bold;
}

.check-password-container input:checked ~ .check-password-checkmark {
  background-color: #00aa6f;
  border: 1px solid #00aa6f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-password-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  font-weight: bold;
}

/* Show the checkmark when checked */
.check-password-container input:checked ~ .check-password-checkmark:after {
  display: block;
  font-weight: bold;
}

/* Style the checkmark/indicator */
.check-password-container .check-password-checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
