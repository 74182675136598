@import "../foundation/global-settings.scss";

.accordion {
    border: 1px solid transparent;
    border-radius: $border-radius-small;
}

.accordian-inner {
    margin-bottom: 0.5rem;
}

.accordian-body {
    padding: $spacing-padding-medium;
    border: 1px solid $cyan;
    border-radius: 0rem 0rem $border-radius-small $border-radius-small;
    border-top: none;
    transition: $transition-1;
}

// accordian toggle button with icon
// ------------------------------------------------------------------

button.accordian-button {
    border: 1px solid $slate-30;
    border-radius: $border-radius-small;
    padding: $spacing-padding-small $spacing-padding-medium;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    text-align: left;
    font-weight: $font-weight-bold;
    font-family: $lato-bold;
    color: $black;
    text-decoration: none;
    background-color: transparent;
    font-size: $font-body-medium;
    transition: $transition-2;
}

.icon-accordian {
    color: $black;
    font-size: $font-heading-medium;
    transition: $transition-2;
}

// colour changes the accordian
// ------------------------------------------------------------------

[data-toggle="collapse"].accordian-button {
    border: 1px solid $cyan;
    border-radius: $border-radius-small $border-radius-small 0 0;
    border-bottom: none;

    // hover
    @media (hover: hover) {
        &:hover {
            border: 1px solid $cyan;
            border-bottom: none;
        }
    }

    // Click
    &:active {
        border: 1px solid $cyan;
        border-bottom: none;
    }
}

[data-toggle="collapse"].accordian-button.collapsed {
    border: 1px solid $slate-30;
    border-radius: $border-radius-small;
    // hover
    @media (hover: hover) {
        &:hover {
            border: 1px solid $black;
        }
    }

    // Click
    &:active {
        border: 1px solid $cyan;
    }
}

[data-toggle="collapse"].accordian-button.collapsed > .icon-accordian {
    -webkit-transform: rotate3d(1, 0, 0, 180deg);
    transform: rotate3d(1, 0, 0, 180deg);
    color: $black;
    transition: $transition-3;
}
@media (hover: hover) {
    [data-toggle="collapse"].accordian-button:hover > .icon-accordian {
        color: $cyan;
    }
}

[data-toggle="collapse"].accordian-button > .icon-accordian {
    color: $cyan;
}

.collapse.show {
    transition: $transition-1;
}

.py-7px {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.fit-content-height {
    height: fit-content !important;
}