@import "../foundation/global-settings.scss";

//  Progress
//  -----------------------------------------

//  Progress Bar
//  -----------------------------------------

.progress {
  border-radius: 0.5rem;
  padding: 0.25rem;
  background-color: $slate-10;
  overflow: visible;
  transition: $transition-3;
}

.progress-bar {
  border-radius: 0.25rem;
  text-align: end;
  overflow: visible;
  transition: $transition-3;
}

.progress-text {
  width: 2rem;
  height: 2rem;
  background-color: $cyan;
  margin-top: -0.75rem;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  padding-top: 1rem;
  margin-left: -0.25rem;
}

//  Spinner
//  ------------------------------------------

.loader-center-container {
  text-align: center;
  padding: 32px 0px;
  &.full-height {
    min-height: 100vh;
  }
}

.loader {
  background-color: $gold;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  -webkit-animation: grow 1.5s ease-out infinite;
  animation: grow 1.5s ease-out infinite;
}

@-webkit-keyframes grow {
  0% {
    transform: scale(0.1);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
